const environment = process.env.NODE_ENV
    ? process.env.NODE_ENV.trim()
    : 'production';

const reactEnv = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV.trim()
    : 'production';

// const environment = 'development';
// const reactEnv = 'development';

console.log('firebase react env: ', reactEnv);
console.log('firebase env: ', environment);

const FIREBASE_CONFIG = {
    production: {
        apiKey: 'AIzaSyA0vCBBxHpJ5iRvh3fa-yYYS5kcSnkk2tk',
        authDomain: 'app.myletterbox.io',
        databaseURL: 'https://testingapp2-296e1.firebaseio.com',
        projectId: 'testingapp2-296e1',
        storageBucket: 'testingapp2-296e1.appspot.com',
        messagingSenderId: '277919628491',
        appId: '1:277919628491:web:d0401a4397e54d8c9e6f4e',
    },
    development: {
        apiKey: 'AIzaSyCmpJ_0KziB6poJLFh0Ofjsb_7fT3uYFiI',
        authDomain: 'exam-3bd69.firebaseapp.com',
        databaseURL: 'https://exam-3bd69-default-rtdb.firebaseio.com',
        projectId: 'exam-3bd69',
        storageBucket: 'exam-3bd69.appspot.com',
        messagingSenderId: '59152980688',
        appId: '1:59152980688:web:9dc6be869cee598e061cd5',
    },
    staging: {
        apiKey: 'AIzaSyCmpJ_0KziB6poJLFh0Ofjsb_7fT3uYFiI',
        authDomain: 'exam-3bd69.firebaseapp.com',
        databaseURL: 'https://exam-3bd69-default-rtdb.firebaseio.com',
        projectId: 'exam-3bd69',
        storageBucket: 'exam-3bd69.appspot.com',
        messagingSenderId: '59152980688',
        appId: '1:59152980688:web:9dc6be869cee598e061cd5',
    },
};

export default FIREBASE_CONFIG[reactEnv || environment];
