import MainHeading from '../../../shared/MainHeading';
import ArticleFromPcForm from '../ArticleFromPcForm';
import PersonalNewsletters from '../PersonalNewsletters';
import './SettingsInProfile.scss';
const SettingsInProfile = () => {
    return (
        <div className='settings-section-profile-page'>
            <PersonalNewsletters/>
        </div>
    );
};

export default SettingsInProfile;
