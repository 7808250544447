import LayoutMain from '../../layouts/LayoutMain';
import MainHeading from '../../shared/MainHeading';
import ExploreNav from './ExploreNav';
import Categories from '../../shared/Categories';
import PopularCards from './PopularCards';
import LatestCards from './LatestCards';
import LoadMore from '../../shared/LoadMore';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    fetchNumberExplorePages,
    fetchNumberExploreCategoryPages,
    clearDailyNewsletters,
} from '../../../actions/newsletterActions';
import { useLocation } from 'react-router-dom';
import { prettifySubPath } from '../../../helpers/textModifier';
import { clearFavoritesArticles } from '../../../actions/articleActions';
import { sendAmplitudeData } from '../../../utils/amplitude';
import PublicFolderModalGuest from '../../shared/PublicFolderModalGuest';
import './Explore.scss';
import { getAllCategories } from '../../../reducers/categoryReducer';

const Explore = ({
    clearFavoritesArticles,
    clearDailyNewsletters,
    categories,
}) => {
    const [pages, setPages] = useState(0);
    const [amplitude, setAmplitude] = useState(true);
    const [modal, setModal] = useState(false);

    let { pathname } = useLocation();
    let paths = pathname.split('/');
    let category = prettifySubPath(paths[paths.length - 1]);

    useEffect(() => {
        if (amplitude) {
            //analytics
            sendAmplitudeData('Page Viewed', {
                path: window.location.pathname,
                title: `Myletterbox - Explore`,
                url: window.location.href,
            });
            setAmplitude(false);
        }

        clearDailyNewsletters();
        clearFavoritesArticles();
        async function fetchPages() {
            if (pathname !== '/explore') {
                if (categories.length) {
                    category = categories.filter(
                        (c) =>
                            c.name.toLocaleLowerCase() ===
                            category.toLocaleLowerCase()
                    )[0].name;
                    const count = await fetchNumberExploreCategoryPages(
                        category
                    );
                    setPages(Math.ceil(count / 8));
                }
            } else {
                const count = await fetchNumberExplorePages();
                setPages(Math.ceil(count / 8));
            }
        }

        fetchPages();
    }, [pathname, category, categories]);

    return (
        // <LayoutMain>
        <>
            {modal && <PublicFolderModalGuest setModal={setModal} />}
            <div className='explore-main-section-wrapper'>
                <div className='explore-header'>
                    <h2>Explore</h2>
                    <ExploreNav />
                </div>
                <div className='explore-main-section'>
                    <Categories />
                    <div className='cards-wrapper'>
                        {/* <PopularCards setModal={setModal} /> */}
                        <LatestCards setModal={setModal} />
                        <LoadMore
                            pages={pages}
                            pathname={pathname}
                            category={category}
                        />
                    </div>
                </div>
            </div>
        </>
        // </LayoutMain>
    );
};

const mapStateToProps = (state) => ({
    categories: getAllCategories(state),
});
const mapDispatchToProps = {
    clearFavoritesArticles,
    clearDailyNewsletters,
};
export default connect(mapStateToProps, mapDispatchToProps)(Explore);
