import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    useHistory,
} from 'react-router-dom';
import ComingSoon from './components/Pages/ComingSoon';
import Home from './components/Pages/Home';
import SignUpPassword from './components/Pages/SignUpPassword';
import OnboardingUsername from './components/Pages/OnboardingUsername';
import OnboardingNewsletters from './components/Pages/OnboardingNewsletters';
import ForgottenPassword from './components/Pages/ForgottenPassword';
import EnterNewPassword from './components/Pages/EnterNewPassword';
import Explore from './components/Pages/Explore';
import Today from './components/Pages/Today';
import Favorites from './components/Pages/Favorites';
import Admin from './components/Pages/Admin';
import Newsletter from './components/Pages/Newsletter';
import Article from './components/Pages/ArticleReadEmbedd';
import Profile from './components/Pages/Profile';
import SearchPage from './components/Pages/SearchPage';
import { connect } from 'react-redux';
import { verifyAuth } from '../src/actions/userActions';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PrivateRoute from './hoc/PrivateRoute';
import AdminRoute from './hoc/AdminRoute';
import PublicRoute from './hoc/PublicRoute';
import { initAmplitude } from './utils/amplitude';
import PublicFolder from './components/Pages/PublicFolder';
import LayoutMain from './components/layouts/LayoutMain';
import { initPixel } from './utils/facebook-pixel';

function App({ verifyAuth }) {
    useEffect(() => {
        verifyAuth();
    }, [verifyAuth]);

    initAmplitude();
    initPixel();
    const history = useHistory();
    return (
        <BrowserRouter>
            <div className='App'>
                <Switch>
                    <PublicRoute path='/' exact component={Home} />
                    {/* <PublicRoute
                        path='/sign-up-password'
                        component={SignUpPassword}
                    /> */}
                    <PrivateRoute 
                        path='/choose-username'
                        component={OnboardingUsername}
                    />
                    {/* <PrivateRoute
                        exact
                        path='/onboarding'
                        component={OnboardingNewsletters}
                    /> */}
                    <PrivateRoute
                        path='/onboarding/:category'
                        component={OnboardingNewsletters}
                    />
                    <PublicRoute
                        path='/new-password'
                        component={EnterNewPassword}
                    />
                    <Route
                        path='/forgotten-password'
                        component={ForgottenPassword}
                    />
                    <LayoutMain>
                        <Route path='/folder/:id' component={PublicFolder} />
                        <Route path='/search' component={SearchPage} />
                        <Route path='/explore' component={Explore} />

                        <Route path='/admin' component={Admin} />
                        <Route
                            path='/newsletters/:newsletterId'
                            component={Newsletter}
                        />
                        <Route
                            path='/favorites'
                            exact
                            component={Favorites}
                        />
                        <Route path='/latest' exact component={Today} />
                        <Route
                            path='/favorites/articles/:id'
                            component={Article}
                        />
                        <Route
                            path='/latest/articles/:id'
                            component={Article}
                        />
                        <Route
                            path='/articles/:id'
                            component={Article}
                        />
                        <Route path='/profile' component={Profile} />
                    </LayoutMain>
                </Switch>
            </div>
        </BrowserRouter>
    );
}

const mapDispatchToProps = {
    verifyAuth,
};

export default connect(null, mapDispatchToProps)(App);
