import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from './assets/button-arrow.svg';
import './ArticleHiddenNext.scss';

const ArticleHiddenNext = ({
    title,
    newsletterName,
    newsletterLogo,
    time,
    url,
    newsletterId,
}) => {
    const handleClick = () => {
        window.scroll(0, 0);
    };

    return (
        <div className='article-next-hidden-wrapper'>
            <p className='next-article-hidden-indicator'>Next Article</p>
            <div className='next-article-hidden-title-wrapper'>
                <div className='next-article-hidden-title'>
                    <Link to={url} onClick={handleClick}>
                        {title}
                    </Link>
                </div>
            </div>
            <div className='next-article-hidden-info-wrapper'>
                <div className='next-article-hidden-info'>
                    <Link to={`/newsletters/${newsletterId}`}>
                        <img
                            className='next-article-hidden-read-bullet'
                            src={newsletterLogo}
                            alt={newsletterName}
                        ></img>
                    </Link>
                    <div className='next-article-hidden-publisher-time'>
                        <Link to={`/newsletters/${newsletterId}`}>
                            {newsletterName}
                        </Link>
                        <p>{time}</p>
                    </div>
                </div>
                <button className='next-article-hidden-button'>
                    <span>Read</span>
                    <ArrowIcon />
                </button>
            </div>
        </div>
    );
};

export default ArticleHiddenNext;
