import {
    FETCH_USERS,
    CHANGE_STATUS,
    CHANGE_PLAN
} from '../actionTypes/usersTypes'
import userService from '../services/userService';

const fetchUsersSuccess = (data) => ({
    type: FETCH_USERS,
    payload: data,
});

const changeStatusSuccess = (user) => ({
    type: CHANGE_STATUS,
    payload: user,
});

const changeUserPlanSuccess = (user) => ({
    type: CHANGE_PLAN,
    payload: user,
});

export const fetchUsers = () => async (dispatch) => {
    try {
        const response = await userService.findAllUsersDetails();
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchUsersSuccess(data));
    } catch (error) {
        console.log(error);
    }
};

export const changeStatusUser = (userId, isBlocked) => async (dispatch) => {
    try {
        const response = await userService.changeStatus(userId, isBlocked);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(changeStatusSuccess({ id: userId, isBlocked }));
    } catch (error) {
        console.log(error);
    }
};

export const changeUserPlan = (userId) => async (dispatch) => {
    try {
        const response = await userService.changeUserPlan(userId);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(changeUserPlanSuccess(data));
    } catch (error) {
        console.log(error);
    }
}