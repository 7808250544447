import LayoutMain from '../../layouts/LayoutMain';
import MainHeading from '../../shared/MainHeading';
import ProfilePicture from './ProfilePicture';
import ProfileForm from './ProfileForm';
import { getUserRole } from '../../../reducers/userReducer';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clearFavoritesArticles } from '../../../actions/articleActions';
import {
    clearDailyNewsletters,
    clearPrivateNewslettersUser,
} from '../../../actions/newsletterActions';
import { sendAmplitudeData } from '../../../utils/amplitude';
import SettingsInProfile from './SettingsInProfile';
import Subscriptions from './Subscriptions';
import { useHistory } from 'react-router-dom';
import { getFromLS } from '../../../utils/storage';
import './Profile.scss';

const Profile = ({
    clearFavoritesArticles,
    clearDailyNewsletters,
    clearPrivateNewslettersUser,
    userRole,
}) => {
    const [amplitude, setAmplitude] = useState(true);
    const history = useHistory();

    if (!getFromLS('role')) {
        history.push('/');
    }

    useEffect(() => {
        clearFavoritesArticles();
        clearDailyNewsletters();

        if (amplitude) {
            //analytics
            sendAmplitudeData('Page Viewed', {
                path: window.location.pathname,
                title: `Myletterbox - Profile`,
                url: window.location.href,
            });
            setAmplitude(false);
        }

        return function cleanup() {
            clearPrivateNewslettersUser();
        };
    }, [userRole]);

    return (
        // <LayoutMain>
        <div className='profile-section-wrapper'>
            <MainHeading
                wrapperClassName='main-heading-onboard-wrapper'
                className='normal-heading'
            >
                My Profile
            </MainHeading>
            <div className='profile-main-section'>
                <ProfilePicture />
                {userRole !== 'ADMIN' ? <Subscriptions /> : null}
                <ProfileForm />
            </div>
            <SettingsInProfile />
        </div>
        // </LayoutMain>
    );
};

const mapStateToProps = (state) => ({
    userRole: getUserRole(state),
});

const mapDispatchToProps = {
    clearFavoritesArticles,
    clearDailyNewsletters,
    clearPrivateNewslettersUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
