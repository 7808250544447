import InputField from '../../../shared/InputField';
import Button from '../../../shared/Button';
import MainHeading from '../../../shared/MainHeading';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useHistory } from 'react-router-dom';
import { validateLogin } from '../../../../helpers/validators';
import { login } from '../../../../actions/userActions';
import { firebaseErrorHandler } from '../../../../helpers/firebaseErrorHandler';
import './FormSignIn.scss';
import ButtonsSocial from '../../../shared/ButtonsSocial';

const FormSignIn = ({ login }) => {
    const [values, setValues] = useForm({ email: '', password: '' });
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailError('');
        setPasswordError('');
        try {
            setLoading(true);
            validateLogin(
                values.email,
                setEmailError,
                values.password,
                setPasswordError
            );
            await login(values.email, values.password);
            history.push('/latest');
        } catch (error) {
            setLoading(false);
            setValues({ password: '' });
            firebaseErrorHandler(error.code || error.error, setEmailError)
        }
    };

    return (
        <div className='form-sign-in-wrapper'>
            <div className='form-sign-in-content'>
                <MainHeading className={'main-heading-white'}>
                    Sign In
                </MainHeading>
                <form className='form-sign-in' onSubmit={handleSubmit}>
                    <h3>I have account in Myletterbox.io</h3>
                    <InputField
                        placeholder={'Email address'}
                        defaultPlaceholder='Email Address'
                        value={values.email}
                        handler={setValues}
                        name='email'
                        type='email'
                        error={emailError}
                        dark={true}
                        id='sign-in-email'
                    />
                    <InputField
                        placeholder={'Password'}
                        defaultPlaceholder='Password'
                        value={values.password}
                        handler={setValues}
                        name='password'
                        type='password'
                        error={passwordError}
                        dark={true}
                        id='sign-in-password'
                    />

                    {loading ? (
                        <Button disabled>LOADING...</Button>
                    ) : (
                            <Button arrow={true}>Sign in</Button>
                        )}
                </form>
                <span className='forgot-password-wrapper'>
                    <Link to='/forgotten-password'>Forgot password</Link>
                </span>
                <ButtonsSocial actionText={"Login"}/>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    login,
};

export default connect(null, mapDispatchToProps)(FormSignIn);
