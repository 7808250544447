import LayoutMain from '../../layouts/LayoutMain';
import MainHeading from '../../shared/MainHeading';
import ListingArticles from '../../shared/ListingArticles';
import { sendAmplitudeData } from '../../../utils/amplitude';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getFromLS } from '../../../utils/storage';
import { connect } from 'react-redux';
import { getUserNewsletters } from '../../../reducers/userReducer';
import './Today.scss';

const Today = ({ newsletters }) => {
    const [amplitude, setAmplitude] = useState(true);
    const history = useHistory();

    if (!getFromLS('role')) {
        history.push('/');
    }


    useEffect(() => {
        if (amplitude) {
            //analytics
            sendAmplitudeData('Page Viewed', {
                path: window.location.pathname,
                title: `Myletterbox - Latest`,
                url: window.location.href,
            });
            setAmplitude(false);
        }
    });

    return (
        // <LayoutMain>
        <div className={`${newsletters.length === 0 && 'empty-page'} today-section`}>
            <div className='today-title-section-wrapper'>
                <div className='today-title-section'>
                    <MainHeading
                        wrapperClassName='main-heading-onboard-wrapper'
                        className='normal-heading'
                    >
                        Latest
                        </MainHeading>
                    <p className='today-subtitle'>
                        Your feed based on your subscriptions.
                        </p>
                </div>
            </div>
            <div className='today-main-section'>
                <ListingArticles />
            </div>
        </div>
        // </LayoutMain>
    );
};

const mapStateToProps = (state) => ({
    newsletters: getUserNewsletters(state),
});

export default connect(mapStateToProps, null)(Today);
