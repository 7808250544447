import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getFromLS } from '../utils/storage';

function AdminRoute({ component: Component, ...children }) {
    if (getFromLS('role') === 'ADMIN') {
        return (
            <Route {...children} render={(props) => <Component {...props} />} />
        );
    } else {
        // return <Redirect to='/explore' />;
        return null;
    }
}

export default AdminRoute;
