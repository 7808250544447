import {
    OPEN_SIDE_MENU,
    CLOSE_SIDE_MENU,
    OPEN_INPUT_CREATE_FOLDER,
    CLOSE_INPUT_CREATE_FOLDER,
    OPEN_MINI_NEWSLETTERS,
    CLOSE_MINI_NEWSLETTERS,
    OPEN_LOAD_MORE_NEWSLETTERS,
    CLOSE_LOAD_MORE_NEWSLETTERS,
    OPEN_NEWSLETTERS_FOLDER,
    CLOSE_NEWSLETTERS_FOLDER,
    OPEN_SPECIFIC_FOLDER,
    CLOSE_SPECIFIC_FOLDER,
    CLOSE_FEEDBACK_MODAL,
    OPEN_FEEDBACK_MODAL,
    CLOSE_PREMIUM_FEATURES_MODAL,
    OPEN_PREMIUM_FEATURES_MODAL
} from '../actionTypes/sideMenuTypes';

const initialSideMenuState = {
    opened: false,
    createFolder: false,
    miniNewsletters: true,
    loadMoreOpenNewsletters: false,
    newslettersFolder: true,
    foldersOpen: {},
    feedbackModalOpened: false,
    premiumFeaturesModalOpened: false
};

const sideMenu = (state = initialSideMenuState, action) => {
    switch (action.type) {
        case OPEN_SIDE_MENU:
            return {
                ...state,
                opened: true,
            };
        case CLOSE_SIDE_MENU:
            return {
                ...state,
                opened: false,
                createFolder: false,
            };
        case OPEN_NEWSLETTERS_FOLDER:
            return {
                ...state,
                newslettersFolder: true,
            };
        case CLOSE_NEWSLETTERS_FOLDER:
            return {
                ...state,
                newslettersFolder: false,
            };
        case OPEN_LOAD_MORE_NEWSLETTERS:
            return {
                ...state,
                loadMoreOpenNewsletters: true,
            };
        case CLOSE_LOAD_MORE_NEWSLETTERS:
            return {
                ...state,
                loadMoreOpenNewsletters: false,
            };
        case OPEN_INPUT_CREATE_FOLDER:
            return {
                ...state,
                createFolder: true,
            };
        case CLOSE_INPUT_CREATE_FOLDER:
            return {
                ...state,
                createFolder: false,
            };
        case OPEN_MINI_NEWSLETTERS:
            return {
                ...state,
                miniNewsletters: true,
            };
        case CLOSE_MINI_NEWSLETTERS:
            return {
                ...state,
                miniNewsletters: false,
            };
        case OPEN_SPECIFIC_FOLDER:
            const specificFolderOpen = action.payload;
            return {
                ...state,
                foldersOpen: { ...state.foldersOpen, ...specificFolderOpen },
            };
        case CLOSE_SPECIFIC_FOLDER:
            const specificFolderClose = action.payload;
            return {
                ...state,
                foldersOpen: { ...state.foldersOpen, ...specificFolderClose },
            };
        case OPEN_FEEDBACK_MODAL:
            return {
                ...state,
                feedbackModalOpened: true
            };
        case CLOSE_FEEDBACK_MODAL:
            return {
                ...state,
                feedbackModalOpened: false
            };
        case OPEN_PREMIUM_FEATURES_MODAL:
            return {
                ...state,
                premiumFeaturesModalOpened: true
            };
        case CLOSE_PREMIUM_FEATURES_MODAL:
            return {
                ...state,
                premiumFeaturesModalOpened: false
            };
        default:
            return state;
    }
};

export default sideMenu;
export const createFolder = (state) => state.sideMenu.createFolder;
export const getNewslettersFolder = (state) => state.sideMenu.newslettersFolder;
export const getLoadMoreNewsletters = (state) =>
    state.sideMenu.loadMoreOpenNewsletters;
export const getOpenOrClosed = (state) => state.sideMenu.opened;
export const getFoldersOpen = (state) => state.sideMenu.foldersOpen;
export const getFeedbackModalOpen = (state) => state.sideMenu.feedbackModalOpened;
