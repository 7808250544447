import UnpublishedCard from './UnpublishedCard';
import { useEffect,useState } from 'react';
import { fetchAllUnpublishedNewsletters } from '../../../../actions/newsletterActions';
import { getUnpublishedNewsletters } from '../../../../reducers/newsletterReducer';
import { notifyUser } from '../../../../helpers/notifications';
import { connect } from 'react-redux';
import './UnpublishedReview.scss';

const UnpublishedReview = ({
    fetchAllUnpublishedNewsletters,
    unpublished,
    setNotification,
}) => {
    const [render, setRender] = useState(false);

    useEffect(() => {
        try {
            fetchAllUnpublishedNewsletters();
        } catch (error) {
            notifyUser('error', error.error, setNotification);
        }
    }, [fetchAllUnpublishedNewsletters]);

    return (
        <div className='unpublished-review-wrapper'>
            <h2>Unpublished newsletters</h2>
            <div className='unpublished-cards-wrapper'>
                {unpublished.map((u) => (
                    <UnpublishedCard
                        key={u._id}
                        id={u._id}
                        name={u.name}
                        logo={u.logo}
                        description={u.description}
                        subscribers={u.subscribed}
                        emails={u.email}
                        articles={u.articles}
                        setNotification={setNotification}
                        setRender={setRender}
                    />
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    unpublished: getUnpublishedNewsletters(state),
});
const mapDispatchToProps = {
    fetchAllUnpublishedNewsletters,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnpublishedReview);
