import LayoutMain from '../../layouts/LayoutMain';
import MainHeading from '../../shared/MainHeading';
import UnpublishedReview from './UnpublishedReview';
import CategoriesList from './CategoriesList';
import InformationForAdmin from './InformationForAdmin/InformationForAdmin';
import Notification from './Notification';
import { useState,useEffect } from 'react';
import PublishedReview from './PublishedReview/PublishedReview';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import UserList from './UserList';
import './Admin.scss';
import { getUserRole } from '../../../reducers/userReducer';
import { clearCurrentNewsletter } from '../../../actions/newsletterActions';
import { connect } from 'react-redux';

const Admin = ({ userRole,clearCurrentNewsletter }) => {
    const [notification, setNotification] = useState({ type: '', message: '' });
    const [chosenTab, setChosenTab] = useState(0);
    const [locked, setLocked] = useState(true);

    useEffect(() => {
        return () => {
            clearCurrentNewsletter();
        };
    }, []);

    const handleTab = (e, value) => {
        setChosenTab(value);
    };

   if (userRole === "ADMIN" && locked) {
       setLocked(false);
   }

    return (
        <>
            {locked ? null : (
                <div className='admin-main-section'>
                    <Notification notification={notification} />
                    <MainHeading
                        wrapperClassName='main-heading-onboard-wrapper'
                        className='normal-heading'
                    >
                        Admin panel
                    </MainHeading>
                    <InformationForAdmin />
                    <AppBar position='static' className='app-bar-admin'>
                        <Tabs value={chosenTab} onChange={handleTab}>
                            <Tab label='Unpublished' />
                            <Tab label='Published' />
                            <Tab label='Users' />
                            <Tab label='Categories' />
                        </Tabs>
                    </AppBar>
                    <div className='review-section'>
                        {chosenTab === 0 ? (
                            <UnpublishedReview
                                setNotification={setNotification}
                            />
                        ) : chosenTab === 1 ? (
                            <PublishedReview
                                setNotification={setNotification}
                            />
                        ) : chosenTab === 2 ? (
                            <UserList />
                        ) : chosenTab === 3 ? (
                            <CategoriesList setNotification={setNotification} />
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
};

// export default Admin;

const mapStateToProps = (state) => ({
    userRole: getUserRole(state),
});

const mapDispatchToProps = {
    clearCurrentNewsletter,
};


export default connect(mapStateToProps, mapDispatchToProps)(Admin);
