import ExploreCard from '../../../shared/ExploreCard';
import HeadingCards from '../HeadingCards';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPopularNewsletters } from '../../../../reducers/newsletterReducer';
import {
    fetchPopularNewsletters,
    filterPopularNewsletters,
} from '../../../../actions/newsletterActions';
import { prettifySubPath } from '../../../../helpers/textModifier';

import './PopularCards.scss';
import { getAllCategories } from '../../../../reducers/categoryReducer';

const PopularCards = ({
    newsletters,
    fetchPopularNewsletters,
    filterPopularNewsletters,
    setModal,
    categories,
}) => {
    let { pathname } = useLocation();
    let category = prettifySubPath(pathname.split('/').pop());

    useEffect(() => {
        async function fetchPopular() {

            try {
                if (pathname === '/explore') {
                    await fetchPopularNewsletters();
                } else {
                    if (categories.length) {
                        category = categories.filter(
                            (c) =>
                                c.name.toLocaleLowerCase() ===
                                category.toLocaleLowerCase()
                        )[0].name;
                        await filterPopularNewsletters(category);
                    }
                    
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchPopular();
    }, [pathname, category, fetchPopularNewsletters, filterPopularNewsletters,categories]);

    return (
        <div className='popular-cards-wrapper'>
            <HeadingCards>Popular</HeadingCards>
            <div className='explore-cards-wrapper'>
                {newsletters.map((x) => (
                    <ExploreCard
                        category={x.category.name}
                        newsletter={x}
                        description={x.description}
                        articles={x.articles}
                        id={x._id}
                        key={x._id}
                        setModal={setModal}
                    />
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    newsletters: getPopularNewsletters(state),
    categories: getAllCategories(state),
});

const mapDispatchToProps = {
    fetchPopularNewsletters,
    filterPopularNewsletters,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopularCards);
