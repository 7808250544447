import { connect } from 'react-redux';
import {
    fetchNewsletter,
    deleteNewsletter,
    unpublishNewsletter,
} from '../../../../../actions/newsletterActions';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { getCurrentNewsletter } from '../../../../../reducers/newsletterReducer';
import EditNewsletterForm from '../EditNewsletterForm';
import { notifyUser } from '../../../../../helpers/notifications';
import { showAlternativeLogo } from '../../../../../helpers/showAlternativeLogo';
import moment from 'moment';
import './PublishedCard.scss';

const PublishedCard = ({
    name,
    email,
    description,
    subscribed,
    id,
    fetchNewsletter,
    unpublishNewsletter,
    newsletter,
    setNotification,
    setRender,
    deleteNewsletter,
    logo,
    articles,
    dateCreated,
}) => {
    const [editForm, setEditForm] = useState(false);
    const handleEditOpen = async () => {
        if (editForm) {
            setEditForm(false);
        } else {
            await fetchNewsletter(id, 1);
            setEditForm(true);
        }
    };

    const handleDeleteNewsletter = async () => {
        try {
            await deleteNewsletter(id, true);
            notifyUser(
                'success',
                'Successfully deleted Newsletter',
                setNotification
            );
        } catch (err) {
            notifyUser('error', err.error, setNotification);
        }
    };

    const handleUnpublish = async () => {
        try {
            await unpublishNewsletter(id);
            notifyUser('success', 'Moved to unpublished', setNotification);
        } catch (err) {
            notifyUser('error', err.error, setNotification);
        }
    };

    return (
        <div className='published-card-wrapper-admin'>
            <img
                src={logo}
                alt={name}
                onError={showAlternativeLogo}
                className='logo-admin-panel-published'
            />
            <p>
                <span>Date Created: </span>
                {moment(dateCreated).format('MM/DD/YYYY')}
            </p>
            <p>
                <span>Newsletter:</span> {name}
            </p>
            <p>
                <span>Emails:</span>{' '}
                <select>
                    {email.map((e, i) => (
                        <option key={i}>{e}</option>
                    ))}
                </select>
            </p>
            <p>
                <span className='published-main-info'>Description:</span>{' '}
                {description}
            </p>
            <div>
                <span className='published-main-info'>Subscribers:</span>{' '}
                <p className='published-main-info-subscribers'>
                    {subscribed.length}
                </p>
            </div>
            <div className='published-articles'>
                <span>Articles:</span>
                {articles.map((o, i) => (
                    <li key={o._id}>
                        <Link to={`/articles/${o._id}`} target='_blank'>
                            {o.title}
                        </Link>
                    </li>
                ))}
            </div>
            <div className='published-buttons-wrapper'>
                <button className='published-button' onClick={handleEditOpen}>
                    Edit
                </button>
                <button
                    className='published-button'
                    onClick={handleDeleteNewsletter}
                >
                    Delete
                </button>
                {/* <button className='published-button' onClick={handleUnpublish}>
                    Unpublish
                </button> */}
            </div>
            {editForm && (
                <EditNewsletterForm
                    email={email}
                    newsletter={newsletter}
                    setNotification={setNotification}
                    setEditForm={setEditForm}
                    id={id}
                    setRender={setRender}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    newsletter: getCurrentNewsletter(state),
});

const mapDispatchToProps = {
    fetchNewsletter,
    deleteNewsletter,
    unpublishNewsletter,
};
export default connect(mapStateToProps, mapDispatchToProps)(PublishedCard);
