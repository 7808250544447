import {
    getUserNewsletters,
    getUserNewslettersNotInFolder,
} from '../../../../../reducers/userReducer';
import {
    openLoadMoreNewsletters,
    closeLoadMoreNewsletters,
    openNewslettersFolder,
    closeNewslettersFolder,
} from '../../../../../actions/sideMenuActions';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import NewsLettersInFolderNewsletters from '../NewsLettersInFolderNewsletters';
import ScrollbarIndicator from '../ScrollbarIndicator';
import { ReactComponent as ArrowFolder } from '../../assets/Arrow-closed-folder.svg';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';
import { getArticle } from '../../../../../reducers/articleReducer';
import './NewslettersFolder.scss';
import { getNewslettersFolder } from '../../../../../reducers/sideMenuReducer';

const NewslettersFolder = ({
    children,
    newsletters,
    newslettersNotInFolder,
    setViewMoreFolders,
    viewMoreNewsletters,
    setNotification,
    openLoadMoreNewsletters,
    closeLoadMoreNewsletters,
    loadMoreNewsletters,
    article,
    newslettersFolder,
    openNewslettersFolder,
    closeNewslettersFolder,
}) => {
    const [opened, setOpened] = useState(newslettersFolder);
    const [hoverable, setHoverable] = useState(true);
    const [currentNewsletter, setCurrentNewsletter] = useState('');
    const [invisible, setInvisible] = useState(false);
    const locationToUse = useLocation()
        .pathname.split('/')
        .filter((l) => l.trim() !== '');
    const location = useLocation();

    useEffect(() => {
        if (
            !locationToUse.includes('newsletters') &&
            !locationToUse.includes('articles')
        ) {
            setCurrentNewsletter('');
        } else if (
            !locationToUse.includes('articles') &&
            !newslettersNotInFolder.find((n) => n._id === locationToUse[1])
        ) {
            setCurrentNewsletter('');
        } else if (locationToUse.includes('articles')) {
            const articlesNewslettersInFolder = newslettersNotInFolder.reduce(
                (a, c) => {
                    a.push(...c.articles);
                    return a;
                },
                []
            );

            const articleId = locationToUse.pop().toString();

            if (!articlesNewslettersInFolder.includes(articleId)) {
                setCurrentNewsletter('');
            }
        }
    }, [location.pathname, newslettersNotInFolder]);

    const handleViewMore = () => {
        if (viewMoreNewsletters) {
            closeLoadMoreNewsletters();
        } else {
            openLoadMoreNewsletters();
            setViewMoreFolders(false);
        }
    };
    const handleToggleNewsletterFolder = () => {
        if (opened) {
            setOpened(false);
            closeNewslettersFolder();
        } else {
            setOpened(true);
            openNewslettersFolder();
        }
        closeLoadMoreNewsletters();
    };

    return (
        <>
            <div className='single-folder-wrapper-newsletters-first'>
                <div
                    className='single-folder-wrapper-first'
                    onClick={handleToggleNewsletterFolder}
                >
                    <div className='folder-name'>
                        <p className='sibebar-inner-elements'>{children}</p>
                        <ArrowFolder
                            className={`${
                                opened && 'arrow-user-folder-opened'
                            } arrow-user-folder`}
                        />
                    </div>
                </div>
                {opened && (
                    <Droppable droppableId={'1'}>
                        {(provided, snapshot) => {
                            return (
                                <div
                                    className={`${
                                        newslettersNotInFolder &&
                                        newslettersNotInFolder.length < 4
                                            ? 'showMore-shorter-div '
                                            : ''
                                    }${
                                        viewMoreNewsletters && 'showMore'
                                    } newsletters-in-folder ${
                                        currentNewsletter !== ''
                                            ? 'hovered-by-user'
                                            : ''
                                    } ${
                                        invisible
                                            ? 'newsletters-in-folder-invisible'
                                            : ''
                                    }`}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {newslettersNotInFolder.length > 0 ? (
                                        newslettersNotInFolder
                                            .slice(
                                                0,
                                                !viewMoreNewsletters
                                                    ? 5
                                                    : newslettersNotInFolder.length
                                            )
                                            .map((x, i) => (
                                                <NewsLettersInFolderNewsletters
                                                    newsletter={x}
                                                    key={x._id}
                                                    isPublic={x.isPublic}
                                                    index={i}
                                                    hoverable={hoverable}
                                                    setHoverable={setHoverable}
                                                    setNotification={
                                                        setNotification
                                                    }
                                                    currentNewsletter={
                                                        currentNewsletter
                                                    }
                                                    setCurrentNewsletter={
                                                        setCurrentNewsletter
                                                    }
                                                />
                                            ))
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            );
                        }}
                    </Droppable>
                )}
                {/* {opened &&
                    newslettersNotInFolder.length > 5 &&
                    !viewMoreNewsletters && (
                        <ScrollbarIndicator
                            residue={newslettersNotInFolder.length - 5}
                            viewMore={handleViewMore}
                        />
                    )} */}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    newsletters: getUserNewsletters(state),
    newslettersNotInFolder: getUserNewslettersNotInFolder(state),
    newslettersFolder: getNewslettersFolder(state),
    article: getArticle(state),
});

const mapDispatchToProps = {
    openLoadMoreNewsletters,
    closeLoadMoreNewsletters,
    openNewslettersFolder,
    closeNewslettersFolder,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewslettersFolder);
