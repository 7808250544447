export const HTTP = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
};

export const ItemTypes = {
    CARD: 'card',
};
