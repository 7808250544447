import { useState } from 'react';
import { connect } from 'react-redux';

import { createNewCategory } from '../../../../../actions/categoryActions';
import { notifyUser } from '../../../../../helpers/notifications';

import './FormNewCategory.scss';

const FormNewCategory = ({ createNewCategory, setNotification }) => {
    const [name, setName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createNewCategory(name);
            notifyUser(
                'success',
                'Category successfully added!',
                setNotification
            );
            setName('');
        } catch (err) {
            notifyUser('error', err.error, setNotification);
        }
    };

    return (
        <form onSubmit={handleSubmit} className='category-admin-form'>
            <div className='category-add-admin-wrapper-input'>
                <label htmlFor='category-title-admin'>New category:</label>
                <input
                    id='category-title-admin'
                    name='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                ></input>
            </div>

            <button>Add Category</button>
        </form>
    );
};

const mapDispatchToProps = {
    createNewCategory,
};

export default connect(null, mapDispatchToProps)(FormNewCategory);
