import './MainHeading.scss';

const MainHeading = ({ children, wrapperClassName, className }) => {
    return (
        <div className={wrapperClassName || 'main-heading-wrapper'}>
            <h1 className={className || 'main-heading-black'}>{children}</h1>
        </div>
    );
};

export default MainHeading;
