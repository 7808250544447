import {
    OPEN_SIDE_MENU,
    CLOSE_SIDE_MENU,
    OPEN_INPUT_CREATE_FOLDER,
    CLOSE_INPUT_CREATE_FOLDER,
    OPEN_MINI_NEWSLETTERS,
    CLOSE_MINI_NEWSLETTERS,
    OPEN_LOAD_MORE_NEWSLETTERS,
    CLOSE_LOAD_MORE_NEWSLETTERS,
    OPEN_NEWSLETTERS_FOLDER,
    CLOSE_NEWSLETTERS_FOLDER,
    OPEN_SPECIFIC_FOLDER,
    CLOSE_SPECIFIC_FOLDER,
    OPEN_FEEDBACK_MODAL,
    CLOSE_FEEDBACK_MODAL,
    OPEN_PREMIUM_FEATURES_MODAL,
    CLOSE_PREMIUM_FEATURES_MODAL
} from '../actionTypes/sideMenuTypes';

import feedbackService from '../services/feedbackService';

export const openSideMenu = () => ({
    type: OPEN_SIDE_MENU,
});

export const closeSideMenu = () => ({
    type: CLOSE_SIDE_MENU,
});

export const openSpecificFolder = (folderId) => ({
    type: OPEN_SPECIFIC_FOLDER,
    payload: { [folderId]: true }
});

export const closeSpecificFolder = (folderId) => ({
    type: CLOSE_SPECIFIC_FOLDER,
    payload: { [folderId]: false }
});

export const openInputCreateFolder = () => ({
    type: OPEN_INPUT_CREATE_FOLDER,
});

export const closeInputCreateFolder = () => ({
    type: CLOSE_INPUT_CREATE_FOLDER,
});

export const openMiniNewsletters = () => ({
    type: OPEN_MINI_NEWSLETTERS,
});

export const closeMiniNewsletters = () => ({
    type: CLOSE_MINI_NEWSLETTERS,
});

export const openLoadMoreNewsletters = () => ({
    type: OPEN_LOAD_MORE_NEWSLETTERS,
});

export const closeLoadMoreNewsletters = () => ({
    type: CLOSE_LOAD_MORE_NEWSLETTERS,
});

export const openNewslettersFolder = () => ({
    type: OPEN_NEWSLETTERS_FOLDER,
});

export const closeNewslettersFolder = () => ({
    type: CLOSE_NEWSLETTERS_FOLDER,
});

export const openFeedbackModal = () => ({
    type: OPEN_FEEDBACK_MODAL
});

export const closeFeedbackModal = () => ({
    type: CLOSE_FEEDBACK_MODAL
});

export const openPremiumFeaturesModal = () => ({
    type: OPEN_PREMIUM_FEATURES_MODAL
});

export const closePremiumFeaturesModal = () => ({
    type: CLOSE_PREMIUM_FEATURES_MODAL
});

export const sendFeedback = (userEmail, feedback) => async () => {
    try {
        await feedbackService.sendFeedback(
            userEmail,
            feedback
        );
    } catch (error) {
        throw new Error(error);
    }
};
