import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getLatestNewsletters } from '../../../../reducers/newsletterReducer';
import {
    filterLatestNewslettersInitial,
    filterLatestNewsletters,
    fetchNumberExploreCategoryPages,
    clearLatestNewsletters,
} from '../../../../actions/newsletterActions';
import { prettifySubPath } from '../../../../helpers/textModifier';

import ExploreCard from '../../../shared/ExploreCard';
import LoadMoreSinglePages from '../../../shared/LoadMoreSinglePages';
import '../Cards.scss';
import { getAllCategories } from '../../../../reducers/categoryReducer';

const SortedLatestCards = ({
    filterLatestNewslettersInitial,
    filterLatestNewsletters,
    newsletters,
    clearLatestNewsletters,
    category,
    categories,
    disabledLink
}) => {
    let categoryPretty = prettifySubPath(category);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const limit = 8;

    useEffect(() => {
        async function fetchSortedNewsletters() {
            if (categories.length) {
                categoryPretty = categories.filter(
                    (c) =>
                        c.name.toLocaleLowerCase() ===
                        categoryPretty.toLocaleLowerCase()
                )[0].name;
                await filterLatestNewslettersInitial(categoryPretty, limit);
                setCount(await fetchNumberExploreCategoryPages(categoryPretty));
                setPage(2);
            }
        }

        fetchSortedNewsletters();

        return () => {
            clearLatestNewsletters();
        };
    }, [filterLatestNewslettersInitial, categories]);

    const onLoadMoreClickHandler = async () => {
        await filterLatestNewsletters(categoryPretty, limit, page);
        setPage((prevState) => prevState + 1);
    };

    return (
        <div className='onboarding-cards-wrapper'>
            <h2>
                Popular{' '}
                <span className='category-info'>in {categoryPretty}</span>
            </h2>
            <div className='onboarding-cards'>
                {newsletters.map((x) => (
                    <ExploreCard
                        newsletter={x}
                        description={x.description}
                        articles={x.articles}
                        id={x._id}
                        key={x._id}
                        disabledLink={disabledLink}
                    />
                ))}
            </div>
            {newsletters.length > 0 && newsletters.length < count && (
                <LoadMoreSinglePages handler={onLoadMoreClickHandler} />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    newsletters: getLatestNewsletters(state),
    categories: getAllCategories(state),
});

const mapDispatchToProps = {
    filterLatestNewslettersInitial,
    filterLatestNewsletters,
    clearLatestNewsletters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortedLatestCards);
