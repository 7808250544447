import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';

import {
    getUserId,
    getUserPlan,
    getUserEmail,
} from '../../../../reducers/userReducer';
import {
    stripeFetchCheckoutSession,
    stripeManagePortalUrl,
    stripeActionNeeded,
} from '../../../../actions/userActions';
import { openPremiumFeaturesModal } from '../../../../actions/sideMenuActions';

import { STRIPE_CONFIG } from '../../../../config/config';
import { trackWithPixel } from '../../../../utils/facebook-pixel';

import './Subscriptions.scss';

const stripePromise = loadStripe(STRIPE_CONFIG.publicKey);

function Subscriptions({
    userId,
    userPlan,
    userEmail,
    stripeFetchCheckoutSession,
    stripeManagePortalUrl,
    stripeActionNeeded,
    openPremiumFeaturesModal
}) {
    useEffect(() => { }, [
        userPlan,
        userEmail,
        userId,
        stripeActionNeeded,
        stripeFetchCheckoutSession,
        stripeManagePortalUrl,
    ]);

    const handleActionNeeded = async (event) => {
        if (userId) {
            const session = await stripeActionNeeded(userId);
            window.location.href = session.url;
        }
    };

    const handleClick = async (event) => {
        const stripe = await stripePromise;
        const session = await stripeFetchCheckoutSession(userId);

        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        trackWithPixel('Initiate Checkout', { userId })
        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };

    return (
        <>
            <div className='profile-account-plan'>
                {userPlan === 'BASE' ? (
                    <button
                        type='button'
                        className='premium-button'
                        role='link'
                        onClick={handleClick}
                    >
                        Premium Membership
                    </button>
                ) : userPlan === 'PREMIUM' ? (
                    <button
                        onClick={openPremiumFeaturesModal}
                        type='button'
                        className='premium-button'
                        role='link'
                    >
                        Manage plan and billings
                    </button>
                ) : (
                    <button
                        type='button'
                        className='premium-button'
                        role='link'
                        onClick={handleActionNeeded}
                    >
                        Confirm Payment
                    </button>
                )}
            </div>
            <div className="user-plan-note">
                <span>PLAN: <strong>{userPlan}</strong></span>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    userPlan: getUserPlan(state),
    userEmail: getUserEmail(state),
});

const mapDispatchToProps = {
    stripeFetchCheckoutSession,
    stripeManagePortalUrl,
    stripeActionNeeded,
    openPremiumFeaturesModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
