import { connect } from 'react-redux';
import { editNewsletterInfo } from '../../../../../actions/newsletterActions';
import { getAllCategories } from '../../../../../reducers/categoryReducer';
import { fetchAllCategories } from '../../../../../actions/categoryActions';
import { notifyUser } from '../../../../../helpers/notifications';
import { useState, useEffect } from 'react';
import './FormPublishNewsletter.scss';

const FormPublishNewsletter = ({
    id,
    name,
    logo,
    category,
    description,
    editNewsletterInfo,
    fetchAllCategories,
    categories,
    setNotification,
}) => {
    const [values, setValues] = useState({
        logo,
        name,
        description,
        category: category || '',
    });

    useEffect(() => {
        fetchAllCategories();
    }, [fetchAllCategories]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await editNewsletterInfo({ ...values, isPublic: true }, id);
            notifyUser(
                'success',
                'Newsletter published successfully!',
                setNotification
            );
        } catch (err) {
            notifyUser('error', err.error, setNotification);
        }
    };

    return (
        <form onSubmit={handleSubmit} className='newsletter-admin-form'>
            <div>
                <label htmlFor='logo-admin'>Logo:</label>
                <input
                    id='logo-admin'
                    name='logo'
                    value={values.logo}
                    onChange={(e) =>
                        setValues({ ...values, logo: e.target.value })
                    }
                ></input>
            </div>
            <div>
                <label htmlFor='name-admin'>Newsletter name:</label>
                <input
                    id='name-admin'
                    name='name'
                    value={values.name}
                    onChange={(e) =>
                        setValues({ ...values, name: e.target.value })
                    }
                ></input>
            </div>
            <div>
                {categories[0] && (
                    <>
                        <label htmlFor='category-admin'>Category:</label>
                        <select
                            name='category'
                            value={values.category}
                            onChange={(e) =>
                                setValues({
                                    ...values,
                                    category: e.target.value,
                                })
                            }
                        >
                            <option value=''>Choose category</option>
                            {categories.map((c) => (
                                <option key={c._id} value={c.name}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                    </>
                )}
            </div>
            <div>
                <label htmlFor='description-admin'>Description:</label>
                <textarea
                    id='description-admin'
                    name='description'
                    value={values.description}
                    onChange={(e) =>
                        setValues({ ...values, description: e.target.value })
                    }
                ></textarea>
            </div>
            <button>Submit</button>
        </form>
    );
};

const mapStateToProps = (state) => ({
    categories: getAllCategories(state),
});

const mapDispatchToProps = {
    editNewsletterInfo,
    fetchAllCategories,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormPublishNewsletter);
