import Button from '../../../shared/Button';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { showAlternativeLogo } from '../../../../helpers/showAlternativeLogo';
import { ReactComponent as ArrowIcon } from './assets/arrowIcon.svg';
import {
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
} from '../../../../actions/userActions';
import {
    removeNewsletterFromFolderSuccess
} from '../../../../actions/folderActions';
import {
    getUserId,
    getUserNewsletters,
} from '../../../../reducers/userReducer';
import { sendAmplitudeData } from '../../../../utils/amplitude';
import './NewsletterInfoSection.scss';

const NewsletterInfoSection = ({
    id,
    logo,
    description,
    userNewsletters,
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
    userId,
    name,
    removeNewsletterFromFolderSuccess,
    setIsSubscribedMain
}) => {
    const [isSubscribed, setIsSubscribed] = useState(false);

    useEffect(() => {
        const result = Boolean(userNewsletters.find((x) => x._id === id));
        setIsSubscribed(result);
        setIsSubscribedMain(result);
    }, [userNewsletters]);

    const handleSubscribe = async () => {
        try {
            if (isSubscribed) {
                await unsubscribeFromNewsletter(userId, id);
                await removeNewsletterFromFolderSuccess(id);

                //analytics
                sendAmplitudeData('Unsubscribe', {
                    method: 'newsletter',
                    publisher_name: name,
                    publisher_id: id,
                });
            } else {
                await subscribeForNewsletter(userId, id);

                //analytics
                sendAmplitudeData('Subscribe', {
                    method: 'newsletter',
                    publisher_name: name,
                    publisher_id: id,
                });
            }

            setIsSubscribed(!isSubscribed);
            setIsSubscribedMain(!isSubscribed);
        } catch (error) {
            console.log(error.code || error.error);
        }
    };

    return (
        <div className='newsletter-info-section-wrapper'>
            <div className='newsletter-icon-wrapper'>
                <img src={logo} alt='logo' onError={showAlternativeLogo} />
            </div>
            <div className='newsletter-description-wrapper'>
                <p>{description}</p>
                <h1 className='newsletter-description-wrapper-name'>{name}</h1>
                {userId && (
                    <button
                        onClick={handleSubscribe}
                        className={`${
                            isSubscribed
                                ? 'centered-button-gray'
                                : 'centered-button-orange'
                        } `}
                    >
                        {isSubscribed ? (
                            <span>Subscribed</span>
                        ) : (
                            <span>Subscribe</span>
                        )}
                        <span>
                            <ArrowIcon />
                        </span>
                    </button>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    userNewsletters: getUserNewsletters(state),
});

const mapDispatchToProps = {
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
    removeNewsletterFromFolderSuccess
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsletterInfoSection);
