import LayoutMain from '../../layouts/LayoutMain';
import MainHeading from '../../shared/MainHeading';
import ListingFavoriteArticles from '../../shared/ListingFavoriteArticles';
import { sendAmplitudeData } from '../../../utils/amplitude';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getFromLS } from '../../../utils/storage';
import './Favorites.scss';


const Favorites = () => {
    const [amplitude,setAmplitude] = useState(true);
    const history = useHistory();

    if (!getFromLS('role')) {
        history.push('/');
    }

   
    useEffect(() => {
        if (amplitude) {
            //analytics
            sendAmplitudeData('Page Viewed', {
                path: window.location.pathname,
                title: `Myletterbox - Favorites`,
                url: window.location.href,
            });
            setAmplitude(false);
        }
    });
    
    return (
        // <LayoutMain>
            <div className='favorites-section'>
                <div className='favorites-title-section-wrapper'>
                    <div className='favorites-title-section'>
                        <MainHeading
                            wrapperClassName='main-heading-onboard-wrapper'
                            className='normal-heading'
                        >
                            Favorites
                        </MainHeading>
                        <p className='favorites-subtitle'>
                            All of your favorite articles at one place.
                        </p>
                    </div>
                </div>
                <div className='favorites-main-section'>
                    <ListingFavoriteArticles />
                </div>
            </div>
        // </LayoutMain>
    );
};

export default Favorites;
