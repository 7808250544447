import './LoadMoreSinglePages.scss';

const LoadMoreSinglePages = ({ handler, hidden, id }) => {
    return (
        <div
            className={`${
                hidden && 'hide-load-more'
            } single-page-load-more-button-wrapper`}
        >
            <span className='load-more-single-page' onClick={()=>handler(id)}>
                Load More
            </span>
        </div>
    );
};
export default LoadMoreSinglePages;
