import './FormSignUpEmail.scss';
import InputField from '../../../shared/InputField';
import Button from '../../../shared/Button';
import MainHeading from '../../../shared/MainHeading';
import ButtonsSocial from '../../../shared/ButtonsSocial';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { registerEmail } from '../../../../actions/userActions';
import { register, login } from '../../../../actions/userActions';
import {
    validateEmail,
    checkIfEmailExists,
    validatePassword,
    comparePasswords,
} from '../../../../helpers/validators';
import { trackWithPixel } from '../../../../utils/facebook-pixel';
import { sendAmplitudeData } from '../../../../utils/amplitude';

const FormSignUpEmail = ({ registerEmail, register, login }) => {
    const [values, setValues] = useForm({ registerEmail: '', registerPassword: '', confirmPassword: '' });
    const [error, setError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setPasswordError('');
        try {
            setLoading(true);
            validateEmail(values.registerEmail.trim());
            await checkIfEmailExists(values.registerEmail.trim());
            validatePassword(values.registerPassword.trim());
            comparePasswords(values.registerPassword.trim(), values.confirmPassword.trim());
            await register(values.registerEmail, values.registerPassword.trim());
            await login(values.registerEmail, values.registerPassword.trim());
            history.push('/choose-username');
            sendAmplitudeData('Sign up button clicked', {
                email_entered: values.registerEmail,
            });
            trackWithPixel('Complete Registration', { email: values.registerEmail })
        } catch (error) {
            setLoading(false);

            if (error.error.includes('User')) {
                setError(error.error || error.message);
            } else {
                setPasswordError(error.error || error.message)
            }
        }
    };

    return (
        <div className='form-email-wrapper'>
            <div className='form-email-content'>
                <MainHeading>Sign Up</MainHeading>
                <form className='form-email' onSubmit={handleSubmit}>
                    <h3>Create your account</h3>
                    <InputField
                        placeholder={'Email address'}
                        defaultPlaceholder='Email Address'
                        value={values.email}
                        handler={setValues}
                        name='registerEmail'
                        type='email'
                        error={error}
                        autocomplete={'off'}
                    />
                    <InputField
                        placeholder='Password'
                        type='password'
                        defaultPlaceholder='Password'
                        value={values.password}
                        handler={setValues}
                        name='registerPassword'
                        error={passwordError}
                        id='sign-up-password'
                        autocomplete={'new-password'}
                    />
                    <InputField
                        placeholder='Confirm Password'
                        type='password'
                        defaultPlaceholder='Confirm Password'
                        value={values.confirmPassword}
                        handler={setValues}
                        name='confirmPassword'
                        id='sign-up-confirm'
                    />
                    {loading ? (
                        <Button disabled>LOADING...</Button>
                    ) : (
                            <Button arrow={true}>Sign Up</Button>
                        )}
                </form>
                <ButtonsSocial actionText={'Sign up'}/>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    registerEmail,
    register,
    login,
};

export default connect(null, mapDispatchToProps)(FormSignUpEmail);
