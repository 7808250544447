export const firebaseErrorHandler = (errorCode, setErrorFunction) => {
    switch (errorCode) {
        case 'auth/user-not-found':
        case 'auth/wrong-password':
            setErrorFunction('The user does not exist!');
            break;
        case 'auth/user-disabled':
            setErrorFunction('Access denied!');
            break;
        default:
            setErrorFunction(errorCode);
            break;
    }
};

export const loginError =
    'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.';
