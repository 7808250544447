import { ReactComponent as TodayIcon } from '../assets/today-icon.svg';
import { ReactComponent as ExploreIcon } from '../assets/explore-icon.svg';
import { ReactComponent as FavoritesIcon } from '../assets/favorites-icon.svg';
import { Link } from 'react-router-dom';
import './QuickLinks.scss';

const QuickLinks = ({ location }) => {
    return (
        <>
            <Link to='/latest'>
                <div
                    className={`${
                        location === '/latest' ? 'active-state-icon' : undefined
                    } side-menu-icon-wrapper`}
                >
                    <TodayIcon className='svg-icon' />
                </div>
            </Link>
            <Link to='/explore'>
                <div
                    className={`${
                        location && location.split('/').includes('explore')
                            ? 'active-state-icon'
                            : undefined
                    } side-menu-icon-wrapper`}
                >
                    <ExploreIcon className='svg-icon' />
                </div>
            </Link>
            <Link to='/favorites'>
                <div
                    className={`${
                        location === '/favorites'
                            ? 'active-state-icon'
                            : undefined
                    } side-menu-icon-wrapper`}
                >
                    <FavoritesIcon className='svg-icon' />
                </div>
            </Link>
        </>
    );
};

export default QuickLinks;
