import InputCreateFolder from './InputCreateFolder';
import Folder from './Folder';
import { connect } from 'react-redux';
import { getUserEmail, getUserFolders } from '../../../../reducers/userReducer';
import { getLoadMoreNewsletters } from '../../../../reducers/sideMenuReducer';
import {
    openInputCreateFolder,
    closeInputCreateFolder,
} from '../../../../actions/sideMenuActions';
import { createFolder } from '../../../../reducers/sideMenuReducer';
import { useRef, useState } from 'react';
import NewslettersFolder from './NewslettersFolder';
import './UserFolders.scss';
import ScrollbarIndicator from './ScrollbarIndicator';
import ProfileMenu from '../../ProfileMenu';

const UserFolders = ({
    userEmail,
    folders,
    createFolderInput,
    openInputCreateFolder,
    options,
    setOptions,
    setNotification,
    closeInputCreateFolder,
    handleToggleTheme,
    light,
    loadMoreNewsletters,
}) => {
    const [selectedFolder, setSelectedFolder] = useState(-1);
    const [folderInfo, setFolderInfo] = useState({ name: '', folderId: '' });
    const [viewMoreFolders, setViewMoreFolders] = useState(false);
    const [settingsMenu, setSettingsMenu] = useState(false);
    const [hoverable, setHoverable] = useState(true);

    const clickCreateNewFolder = () => {
        openInputCreateFolder();
        setViewMoreFolders(true);
    };

    const toggleSettingsMenu = () => {
        settingsMenu ? setSettingsMenu(false) : setSettingsMenu(true);
    };

    const handleOpen = async (e, index, id) => {
        try {
            if (index === selectedFolder) {
                setSelectedFolder(-1);
            } else {
                setSelectedFolder(index);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleViewMore = () => {
        setViewMoreFolders(true);
        // setViewMoreNewsletters(false);
    };

    return (
        <>
            <div className='sidemenu-foldres-button-wrapper'>
                <div className='folders-wrapper'>
                    <div className='current-folders'>
                        <NewslettersFolder
                            setViewMoreFolders={setViewMoreFolders}
                            // viewMoreNewsletters={loadMoreNewsletters}
                            viewMoreNewsletters={true}
                            setNotification={setNotification}
                        >
                            Newsletters
                        </NewslettersFolder>
                        <div className='folders-heading-wrapper-side-menu'>
                            <p className='folders-heading-side-menu'>Folders</p>
                            <p
                                className='add-folder-newsletter'
                                onClick={clickCreateNewFolder}
                            >
                                Create+
                            </p>
                        </div>
                        <div
                            className={`${viewMoreFolders
                                ? 'view-more-folders'
                                : undefined
                                } custom-folders-wrapper`}
                        >
                            {createFolderInput ? (
                                <InputCreateFolder
                                    folders={folders}
                                    setNotification={setNotification}
                                    folderInfo={folderInfo}
                                    setFolderInfo={setFolderInfo}
                                    setViewMoreFolders={setViewMoreFolders}
                                />
                            ) : (
                                folders &&
                                folders.map((x, i) => {
                                    let opened = false;
                                    if (i === selectedFolder) {
                                        opened = true;
                                    }
                                    return (
                                        <Folder
                                            key={i}
                                            userEmail={userEmail}
                                            setSelectedFolder={
                                                setSelectedFolder
                                            }
                                            index={i}
                                            // isOpened={opened}
                                            // handleOpen={handleOpen}
                                            folderId={x._id}
                                            options={options}
                                            setOptions={setOptions}
                                            setFolderInfo={setFolderInfo}
                                            setNotification={setNotification}
                                            hoverable={hoverable}
                                            setHoverable={setHoverable}
                                        >
                                            {x.name}
                                        </Folder>
                                    );
                                })
                            )}
                        </div>
                        {/* {!viewMoreFolders &&
                            folders.length >
                                (window.innerHeight > 950 ? 5 : 4) && (
                                <ScrollbarIndicator
                                    residue={
                                        folders.length -
                                        (window.innerHeight > 950 ? 5 : 4)
                                    }
                                    viewMore={handleViewMore}
                                />
                            )} */}
                    </div>
                    <div
                        className='mobile-menu-profile'
                        onClick={toggleSettingsMenu}
                    >
                        My Profile
                        <ProfileMenu
                            className={
                                settingsMenu
                                    ? 'side-menu-settings-visible side-menu-settings-visible-mobile'
                                    : 'side-menu-settings-hidden-mobile'
                            }
                            handleToggleTheme={handleToggleTheme}
                            light={light}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userEmail: getUserEmail(state),
    folders: getUserFolders(state),
    createFolderInput: createFolder(state),
    loadMoreNewsletters: getLoadMoreNewsletters(state),
});

const mapDispatchToProps = {
    openInputCreateFolder,
    closeInputCreateFolder,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserFolders);
