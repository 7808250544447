import CheckIn from '../../layouts/CheckIn';
import { ReactComponent as UsernamePicture } from '../assets/white-logo.svg';
import ForgottenPasswordForm from './ForgottenPasswordForm';
import './ForgottenPassword.scss';

const ForgottenPassword = () => {
    return (
        <>
            <div className='username-page-wrapper'>
                <CheckIn noBackground={true}>
                    <div className='forgotten-central-section-wrapper'>
                        <div className='forgotten-central-section'>
                            <ForgottenPasswordForm className='form-forgotten' />
                            <UsernamePicture className='forgotten-picture-pages' />
                        </div>
                    </div>
                </CheckIn>
            </div>
        </>
    );
};

export default ForgottenPassword;
