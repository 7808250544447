import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getLatestNewsletters } from '../../../../reducers/newsletterReducer';
import {
    fetchLatestNewslettersInitial,
    fetchLatestNewsletters,
    fetchNumberExplorePages,
    clearLatestNewsletters,
} from '../../../../actions/newsletterActions';

import ExploreCard from '../../../shared/ExploreCard';
import LoadMoreSinglePages from '../../../shared/LoadMoreSinglePages';

import '../Cards.scss';

const LatestCards = ({
    fetchLatestNewslettersInitial,
    fetchLatestNewsletters,
    newsletters,
    clearLatestNewsletters,
    disabledLink
}) => {
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const limit = 8;

    useEffect(() => {
        async function fetchNewsletters() {
            await fetchLatestNewslettersInitial(limit, page);
            setCount(await fetchNumberExplorePages());
            setPage((prevState) => prevState + 1);
        }

        fetchNewsletters();

        return () => {
            clearLatestNewsletters();
        };
    }, [fetchLatestNewslettersInitial]);

    const onLoadMoreClickHandler = async () => {
        await fetchLatestNewsletters(limit, page);
        setPage((prevState) => prevState + 1);
    };

    return (
        <div className='onboarding-cards-wrapper'>
            <h2>Popular</h2>
            <div className='onboarding-cards'>
                {newsletters.map((x) => (
                    <ExploreCard
                        newsletter={x}
                        description={x.description}
                        articles={x.articles}
                        id={x._id}
                        key={x._id}
                        disabledLink={disabledLink}
                    />
                ))}
            </div>
            {newsletters.length < count && (
                <LoadMoreSinglePages handler={onLoadMoreClickHandler} />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    newsletters: getLatestNewsletters(state),
});

const mapDispatchToProps = {
    fetchLatestNewslettersInitial,
    fetchLatestNewsletters,
    clearLatestNewsletters,
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestCards);
