import OpenSideMenu from './OpenSideMenu';
import { ReactComponent as LogoOpenSideMenu } from './assets/Logo-collapsed-menu-new.svg';
import QuickAccessIcons from './QuickAccessIcons';
import ProfileMenu from '../../shared/ProfileMenu';
import { connect } from 'react-redux';
import { openSideMenu, closeSideMenu } from '../../../actions/sideMenuActions';
import { fetchUsersFolders } from '../../../actions/folderActions';
import { getUserFolders, getUserId } from '../../../reducers/userReducer';
import { ReactComponent as ArrowToCloseMenu } from './assets/Arrow-close-side-menu.svg';
import React, { useEffect } from 'react';
import './SideMenu.scss';
import { fetchNewslettersNotInFolder } from '../../../actions/newsletterActions';

const SideMenu = ({
    opened,
    openSideMenu,
    closeSideMenu,
    folders,
    fetchUsersFolders,
    userId,
    setNotification,
    menuSettingsClick,
    settingsMenu,
    handleToggleTheme,
    light,
    setVisibilitySettings,
    fetchNewslettersNotInFolder
}) => {
    const handleSideMenu = () => {
        if (opened) {
            closeSideMenu();
            localStorage.setItem('side-menu-state', 'closed');
        } else {
            openSideMenu();
            localStorage.setItem('side-menu-state', 'opened');
        }

        if (folders.length === 0 && userId) {
            fetchUsersFolders(userId);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('side-menu-state')) {
            const state = localStorage.getItem('side-menu-state');

            if (state === 'opened') {
                openSideMenu();

                if (folders.length === 0 && userId) {
                    fetchUsersFolders(userId);
                }
            }
            if (state === 'closed') closeSideMenu();
        }
    }, [openSideMenu, closeSideMenu, userId, fetchUsersFolders, folders.length]);

    const handleCloseProfileMenu = (e) => {
        settingsMenu && setVisibilitySettings(false);
    };

    return (
        <div className='menu-wrapper'>
            <aside
                className={opened ? 'side-menu side-menu-opened' : 'side-menu'}
            >
                <div
                    className='side-menu-content'
                    onClick={handleCloseProfileMenu}
                >
                    <div className='burger-icon-wrapper'>
                        <div onClick={handleSideMenu}>
                            <LogoOpenSideMenu className='burger-icon svg-icon-menu-logo' />
                        </div>
                        {opened && (
                            <div
                                className='arrow-wrapper'
                                onClick={handleSideMenu}
                            >
                                {/* <ArrowToCloseMenu className='svg-icon-menu-logo arrow-opened-side-menu' /> */}
                            </div>
                        )}
                    </div>
                    {opened ? (
                        <OpenSideMenu
                            handleSideMenu={handleSideMenu}
                            setNotification={setNotification}
                            menuSettingsClick={menuSettingsClick}
                        />
                    ) : (
                        <QuickAccessIcons
                            openMenu={handleSideMenu}
                            menuSettingsClick={menuSettingsClick}
                        />
                    )}
                </div>
            </aside>
            <ProfileMenu
                className={
                    settingsMenu
                        ? 'side-menu-settings-visible'
                        : 'side-menu-settings-hidden'
                }
                handleToggleTheme={handleToggleTheme}
                light={light}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    opened: state.sideMenu.opened,
    folders: getUserFolders(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    openSideMenu,
    closeSideMenu,
    fetchUsersFolders,
    fetchNewslettersNotInFolder,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
