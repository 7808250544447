import getFolderLink from '../../../../../helpers/getFolderLink';
import { textCutterForFoldersAndNewslettrsInFolders } from '../../../../../helpers/textCutter';
import './ModalPublicFolder.scss';

const ModalPublicFolder = ({
    setModal,
    folderName,
    setNotification,
    notifyUser,
    folderId,
    setHoverable,
}) => {
    const link = getFolderLink(folderId);
    const handleCloseModal = () => {
        setModal(false);
        setHoverable(true);
    };

    const handleCopyFolderLink = () => {
        navigator.clipboard.writeText(link);
        notifyUser('success', 'Link Copied', setNotification);
        setModal(false);
        setHoverable(true);
    };

    return (
        <>
            <div className='modal-public-folder'>
                <div className='modal-content-public-folder'>
                    <div className='modal-button-close-public-folder'>
                        <p onClick={handleCloseModal}>Close</p>
                    </div>
                    <div className='modal-header-public-folder'>
                        <h4 className='modal-title-public-folder'>
                            {folderName} is now public.
                        </h4>
                    </div>
                    <div className='modal-body-public-folder'>
                        You can now share it with friends.
                    </div>
                    <div className='disabled-input-field'>
                        <p className='link-public-folder-modal'>
                            {textCutterForFoldersAndNewslettrsInFolders(
                                link,
                                30
                            )}
                        </p>
                        <div
                            className='copy-public-folder-link-modal'
                            onClick={handleCopyFolderLink}
                        >
                            Copy
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalPublicFolder;
