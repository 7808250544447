import { useLocation } from 'react-router-dom';
import { prettifySubPath } from '../../../../helpers/textModifier';
import './HeadingCards.scss';

const HeadingCards = ({ children }) => {
    let { pathname } = useLocation();
    const [_, mainPath, subPath] = pathname.split('/');
    const isCategory = subPath && subPath !== 'collections';
    const isInCollections = subPath === 'collections';

    return (
        <div className='cards-heading-wrapper'>
            <h3>
                {children}{' '}
                {isCategory && (
                    <span className='isCategory'>{`in ${prettifySubPath(
                        subPath
                    )}`}</span>
                )}
                {isInCollections && (
                    <span className='isCollections'>collections</span>
                )}
            </h3>
        </div>
    );
};

export default HeadingCards;
