import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    fetchLatestNewsletters,
    filterLatestNewsletters,
} from '../../../actions/newsletterActions';
import './LoadMore.scss';

const LoadMore = ({
    filterLatestNewsletters,
    fetchLatestNewsletters,
    pages,
    category,
    pathname,
}) => {
    const [visibleLoadMore, setVisibleLoadMore] = useState(true);
    const limit = 8;

    let page = 1;
    const onLoad = async () => {
        if (pathname === '/explore') {
            fetchLatestNewsletters(limit, ++page);
        } else {
            filterLatestNewsletters(category, limit, ++page);
        }
        pages === page && setVisibleLoadMore(false);
    };
    useEffect(() => {
        pages > 1 ? setVisibleLoadMore(true) : setVisibleLoadMore(false);
    }, [pathname, pages]);

    return (
        <div
            className={`${
                visibleLoadMore ? '' : 'hidden-load-more'
            } load-more-wrapper`}
        >
            <span onClick={onLoad}>Load More</span>
        </div>
    );
};

const mapDispatchToProps = {
    filterLatestNewsletters,
    fetchLatestNewsletters,
};

export default connect(null, mapDispatchToProps)(LoadMore);
