import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchAllPublishedNewsletters } from '../../../../actions/newsletterActions';
import { getAllPublished } from '../../../../reducers/newsletterReducer';
import PublishedCard from './PublishedCard/PublishedCard';
import './PublishedReview.scss';

const PublishedReview = ({
    fetchAllPublishedNewsletters,
    published,
    setNotification,
}) => {
    const [render, setRender] = useState(false);

    useEffect(() => {
        fetchAllPublishedNewsletters();
    }, [fetchAllPublishedNewsletters]);

    return (
        <>
            <div className='published-newsletters-wrapper-admin'>
                <h2>Published newsletters</h2>
                {published.map((x) => (
                    <PublishedCard
                        dateCreated={x.dateAdded}
                        name={x.name}
                        email={x.email}
                        description={x.description}
                        subscribed={x.subscribed}
                        id={x._id}
                        setNotification={setNotification}
                        key={x._id}
                        setRender={setRender}
                        logo={x.logo}
                        articles={x.articles}
                    />
                ))}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    published: getAllPublished(state),
});

const mapDispatchToProps = {
    fetchAllPublishedNewsletters,
};
export default connect(mapStateToProps, mapDispatchToProps)(PublishedReview);
