import { combineReducers } from 'redux';
import user from './userReducer';
import newsletters from './newsletterReducer';
import categories from './categoryReducer';
import articles from './articleReducer';
import users from './usersReducer';
import sideMenu from './sideMenuReducer';

const appReducer = combineReducers({
    user,
    users,
    newsletters,
    articles,
    categories,
    sideMenu,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
