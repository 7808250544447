import {
    LOGIN,
    REGISTER_EMAIL,
    CREATE_USERNAME,
    REGISTER_USER,
    INIT_SUCCESS,
    INIT_FAIL,
    LOGOUT,
    UPDATE_USER_NEWSLETTERS,
    UPDATE_USER_NEWSLETTERS_REMOVE,
    ADD_USER_FOLDER,
    UPDATE_USER_FAVORITES,
    UPDATE_USER_FAVORITES_REMOVE,
    UPDATE_USER_READ,
    UPDATE_USER_READ_REMOVE,
    ADD_NEWSLETTER_TO_FOLDER,
    UPDATE_PHOTO,
    FETCH_USER_NEWSLETTERS,
    FETCH_USER_FAVORITES,
    FETCH_USER_READ,
    LOAD_USER_FOLDERS,
    EDIT_FOLDER_NAME,
    DELETE_USER_FOLDER,
    LOAD_SINGLE_FOLDER,
    SUBSCRIBE_TO_MANY_NEWSLETTERS_FROM_PUBLIC_FOLDER,
    MAKE_FOLDER_PUBLIC,
    MAKE_FOLDER_PRIVATE,
    FETCH_USER_UNSEEN,
    REMOVE_FROM_UNSEEN,
    CHANGE_POSITIONS_NEWSLETTERS,
    LOAD_USER_NEWSLETTERS_NOT_IN_FOLDERS,
    REMOVE_NEWSLETTER_FROM_FOLDER,
    MARK_ALL_ARTICLES_FROM_NEWSLETTER_AS_READ,
    RETURN_NEWSLETTER_TO_MAIN_FOLDER,
    ADD_NEWSLETTER_TO_ANOTHER_FOLDER,
    CHANGE_POSITIONS_NEWSLETTERS_FOLDER,
    FETCH_USER_PLAN
} from '../actionTypes/userTypes';
import { reorder } from '../helpers/arrayHelper';
import { auth } from '../utils/firebase';

const initialState = {
    id: '',
    email: '',
    loggedIn: false,
    plan: '',
    token: '',
    username: '',
    role: '',
    photoURL: '',
    newsletters: [],
    newslettersNotInFolder: [],
    folders: [],
    read: [],
    favorites: [],
    currentFolder: {},
    unseen: [],
};

const handleFolders = (state, action) => {
    const newState = [...state];
    let indexOfFolder = -1;
    let indexOfNewsletter = -1;

    newState.forEach((f, i) => {
        const indexToShow = f.newsletters.findIndex(
            (n) => n._id === action.payload
        );

        if (indexToShow > -1) {
            indexOfFolder = i;
            indexOfNewsletter = indexToShow;
        }
    });

    if (indexOfFolder > -1) {
        newState[indexOfFolder].newsletters.splice(indexOfNewsletter, 1);
    }

    return newState;
};

const folder = (state = {}, { type, payload }) => {
    const { sourceFolderId, folderName, ...newsletter } = payload;

    if (state.name !== folderName && state._id !== sourceFolderId) {
        return state;
    } else if (state._id !== sourceFolderId) {
        switch (type) {
            case ADD_NEWSLETTER_TO_FOLDER:
                return {
                    ...state,
                    newsletters: [...state.newsletters, { ...newsletter }],
                };
            default:
                return state;
        }
    } else {
        switch (type) {
            case ADD_NEWSLETTER_TO_FOLDER:
                return {
                    ...state,
                    newsletters: state.newsletters.filter(
                        (n) => n._id !== newsletter._id
                    ),
                };
            default:
                return state;
        }
    }
};

const folders = (state = [], action) => {
    switch (action.type) {
        case ADD_NEWSLETTER_TO_FOLDER:
            return state.map((x) => folder(x, action));
        case REMOVE_NEWSLETTER_FROM_FOLDER:
            return handleFolders(state, action);
        default:
            return state;
    }
};

const handleUpdateUserNewslettersAndSort = (state, newsletter) => {
    const newState = [...state.newsletters, { ...newsletter }];
    return newState.sort((a, b) =>
        b.lastArticleDate.localeCompare(a.lastArticleDate)
    );
};

const handleNewslettersNotInFolder = (folders, newsletters) => {
    const userNewslettersInFolder = folders.reduce((a, c) => {
        c.newsletters.forEach((x) => !a.includes(x._id) && a.push(x._id));
        return a;
    }, []);
    return newsletters.filter((x) => !userNewslettersInFolder.includes(x._id));
};
const user = (state = initialState, action) => {
    switch (action.type) {
        case INIT_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                ...action.payload,
            };
        case INIT_FAIL:
            return initialState;
        case LOGIN:
            return {
                ...state,
                loggedIn: true,
                email: action.payload.email,
                id: action.payload.id,
            };
        case REGISTER_EMAIL:
            return {
                ...state,
                email: action.payload.email.trim(),
            };
        case REGISTER_USER:
            return {
                ...state,
                token: action.payload.idToken,
                loggedIn: true,
            };
        case CREATE_USERNAME:
            return {
                ...state,
                username: action.payload,
            };
        case FETCH_USER_PLAN:
            return {
                ...state,
                plan: action.payload.plan,
            };
        case FETCH_USER_NEWSLETTERS:
            return {
                ...state,
                // newsletters: action.payload.sort((a, b) =>
                //     b.lastArticleDate.localeCompare(a.lastArticleDate)
                // ),
                newsletters: action.payload.reverse(),
            };
        case FETCH_USER_FAVORITES:
            return {
                ...state,
                favorites: action.payload,
            };
        case FETCH_USER_READ:
            return {
                ...state,
                read: action.payload,
            };
        case REMOVE_FROM_UNSEEN:
        case FETCH_USER_UNSEEN:
            return {
                ...state,
                unseen: action.payload || [],
            };
        case UPDATE_USER_NEWSLETTERS:
            return {
                ...state,
                newsletters: [action.payload, ...state.newsletters],
                newslettersNotInFolder: [
                    action.payload,
                    ...state.newslettersNotInFolder,
                ],
            };
        case UPDATE_USER_NEWSLETTERS_REMOVE:
            const articlesToRemoveFromUnseen = state.newsletters.find(
                (n) => n._id === action.payload.id
            )?.articles;

            let updateUserNewslettersRemoveFolders = state.folders;

            if (action.payload.folder) {
                const indexOfFolder = state.folders.indexOf(
                    action.payload.folder
                );

                if (indexOfFolder > -1) {
                    let updateUserNewslettersRemoveFolder = updateUserNewslettersRemoveFolders[indexOfFolder];
                    updateUserNewslettersRemoveFolder.newsletters = updateUserNewslettersRemoveFolder.newsletters.filter((n) => n._id !== action.payload.id);
                    updateUserNewslettersRemoveFolders[indexOfFolder] = updateUserNewslettersRemoveFolder;
                }
            }

            return {
                ...state,
                newsletters: state.newsletters.filter(
                    (x) => x._id !== action.payload.id
                ),
                newslettersNotInFolder: state.newslettersNotInFolder.filter(
                    (x) => x._id !== action.payload.id
                ),
                unseen: state.unseen.filter(
                    (a) => !articlesToRemoveFromUnseen.includes(a)
                ),
                folders: [...updateUserNewslettersRemoveFolders]
            };
        case UPDATE_USER_FAVORITES:
            return {
                ...state,
                favorites: [
                    ...state.favorites,
                    { dateSaved: new Date(), article: action.payload },
                ],
            };
        case UPDATE_USER_READ:
            return {
                ...state,
                read: [...state.read, action.payload],
            };
        case UPDATE_USER_FAVORITES_REMOVE:
            return {
                ...state,
                favorites: state.favorites.filter(
                    (f) => f.article !== action.payload
                ),
            };
        case UPDATE_USER_READ_REMOVE:
            return {
                ...state,
                read: state.read.filter((r) => r !== action.payload),
            };
        case ADD_USER_FOLDER:
            return {
                ...state,
                folders: [...state.folders, action.payload],
            };
        case EDIT_FOLDER_NAME:
            return {
                ...state,
                folders: state.folders.map((x) =>
                    x._id === action.payload.folderId
                        ? { ...x, name: action.payload.folderName }
                        : { ...x }
                ),
            };
        case DELETE_USER_FOLDER:
            const newNewslettersToAdd = state.folders.find(
                (f) => f._id === action.payload.folderId
            ).newsletters;

            return {
                ...state,
                newslettersNotInFolder: [
                    ...state.newslettersNotInFolder,
                    ...newNewslettersToAdd,
                ],

                folders: state.folders.filter(
                    (x) => x._id !== action.payload.folderId
                ),
            };
        case LOAD_USER_FOLDERS:
            return {
                ...state,
                folders: [...action.payload],
            };
        case UPDATE_PHOTO:
            return {
                ...state,
                photoURL: action.payload,
            };
        case ADD_NEWSLETTER_TO_FOLDER:
            return {
                ...state,
                folders: folders(state.folders, action),
                newslettersNotInFolder: state.newslettersNotInFolder.filter(
                    (n) => n._id !== action.payload._id
                ),
            };
        case CHANGE_POSITIONS_NEWSLETTERS_FOLDER:

            let changePositionsFolders = state.folders.reduce((a,c)=>{
                if (c._id === action.payload.folderId) {
                    let newsletterToRemove = c.newsletters.splice(action.payload.sourceIndex,1);
                    c.newsletters.splice(action.payload.destinationIndex,0,newsletterToRemove[0]);
                }
                a.push(c);
                return a;
            },[]);

            return {
                ...state,
                folders: changePositionsFolders
            };
        case ADD_NEWSLETTER_TO_ANOTHER_FOLDER:
            const newsletterToAdd = state.folders
                .find((f) => f._id === action.payload.folderId)
                .newsletters.find((n) => n._id === action.payload.newsletterId);

            const userFoldersToUse = state.folders;
            let userFolderToRemove = userFoldersToUse.find(
                (f) => f._id === action.payload.folderId
            );
            let indexToRemove = userFoldersToUse.indexOf(userFolderToRemove);
            userFolderToRemove.newsletters =
                userFolderToRemove.newsletters.filter(
                    (n) => n._id !== action.payload.newsletterId
                );

            userFoldersToUse.splice(indexToRemove, 1, userFolderToRemove);

            const userFolderToAdd = userFoldersToUse.find(
                (f) => f._id === action.payload.destinationFolderId
            );
            let indexDestination = userFoldersToUse.indexOf(userFolderToAdd);
            userFolderToAdd.newsletters.push(newsletterToAdd);

            userFoldersToUse.splice(indexDestination, 1, userFolderToAdd);

            return {
                ...state,
                folders: [...userFoldersToUse],
            };

        case RETURN_NEWSLETTER_TO_MAIN_FOLDER:
            const newsletter = state.folders
                .find((f) => f._id === action.payload.folderId)
                .newsletters.find((n) => n._id === action.payload.newsletterId);

            const newsletters = state.newslettersNotInFolder;
            newsletters.splice(action.payload.position, 0, newsletter);

            const userFolders = state.folders;
            let userFolder = userFolders.find(
                (f) => f._id === action.payload.folderId
            );
            let index = userFolders.indexOf(userFolder);
            userFolder.newsletters = userFolder.newsletters.filter(
                (n) => n._id !== action.payload.newsletterId
            );

            userFolders.splice(index, 1, userFolder);

            return {
                ...state,
                folders: [...userFolders],
                newslettersNotInFolder: [...newsletters],
            };
        case REMOVE_NEWSLETTER_FROM_FOLDER:
            return {
                ...state,
                folders: folders(state.folders, action),
            };
        case LOAD_SINGLE_FOLDER:
            return {
                ...state,
                currentFolder: action.payload,
            };
        case SUBSCRIBE_TO_MANY_NEWSLETTERS_FROM_PUBLIC_FOLDER:
            return {
                ...state,
                newsletters: [...state.newsletters, ...action.payload],
                newslettersNotInFolder: [...action.payload,...state.newsletters],
            };
        case MAKE_FOLDER_PUBLIC:
            return {
                ...state,
                folders: state.folders.map((x) =>
                    x._id === action.payload
                        ? { ...x, isPublic: true }
                        : { ...x }
                ),
            };
        case MAKE_FOLDER_PRIVATE:
            return {
                ...state,
                folders: state.folders.map((x) =>
                    x._id === action.payload
                        ? { ...x, isPublic: false }
                        : { ...x }
                ),
            };
        case LOAD_USER_NEWSLETTERS_NOT_IN_FOLDERS:
            return {
                ...state,
                newslettersNotInFolder: action.payload
                    ? [...action.payload]
                    : state.newslettersNotInFolder,
                // : handleNewslettersNotInFolder(
                //       state.folders,
                //       state.newsletters
                //   ),
            };
        case CHANGE_POSITIONS_NEWSLETTERS:
            return {
                ...state,
                newslettersNotInFolder: reorder(
                    state.newslettersNotInFolder,
                    action.payload.startIndex,
                    action.payload.endIndex
                ),
            };
        case MARK_ALL_ARTICLES_FROM_NEWSLETTER_AS_READ:
            return {
                ...state,
                read: [...new Set([...state.read, ...action.payload])],
                unseen: state.unseen.filter((un) => {
                    if (action.payload.includes(un)) {
                        return false;
                    } else {
                        return true;
                    }
                }),
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default user;

export const getUserEmail = (state) => state.user.email;
export const getUserPlan = (state) => state.user.plan || "BASE";
export const getUsername = (state) => state.user.username;
export const getUser = () => auth.currentUser;
export const getUserNewsletters = (state) => state.user.newsletters;
export const getUserFolders = (state) => state.user.folders;
export const getUserReadArticles = (state) => state.user.read;
export const getUserFavorites = (state) => state.user.favorites;
export const getUserToken = (state) => state.user.token;
export const getUserPhoto = (state) => state.user.photoURL;
export const getUserId = (state) => state.user.id;
export const getUserRole = (state) => state.user.role;
export const getUserRead = (state) => state.user.read;
export const getCurrentFolder = (state) => state.user.currentFolder;
export const getUserUnseen = (state) => state.user.unseen || [];
export const getUserNewslettersArticles = (state) =>
    state.user.newsletters.length
        ? [].concat.apply(
              [],
              state.user.newsletters.map((n) => n.articles)
          )
        : [];
export const getUserFavoritesArticlesIds = (state) =>
    state.user.favorites.length
        ? [].concat.apply(
              [],
              state.user.favorites.map((n) => n.article)
          )
        : [];
export const getUserNewslettersNotInFolder = (state) =>
    state.user.newslettersNotInFolder;
