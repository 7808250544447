import { useState, useEffect, useRef } from 'react';
import {
    addFolder,
    editFolderName,
} from '../../../../../actions/folderActions';
import { getUserId } from '../../../../../reducers/userReducer';
import { closeInputCreateFolder } from '../../../../../actions/sideMenuActions';
import { connect } from 'react-redux';
import { notifyUser } from '../../../../../helpers/notifications';
import './InputCreateFolder.scss';

const InputCreateFolder = ({
    addFolder,
    userId,
    closeInputCreateFolder,
    folders,
    setNotification,
    folderInfo,
    setFolderInfo,
    editFolderName,
    setViewMoreFolders,
}) => {
    const [inputValue, setInputValue] = useState(
        folderInfo.name ? folderInfo.name : ''
    );

    const cancelCreate = (e) => {
        e.preventDefault();
        setViewMoreFolders(false);
        closeInputCreateFolder();
        setFolderInfo('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let rename = false;
        try {
            if (inputValue === '') {
                throw new Error('Folders should have name!');
            }

            if (folderInfo.name) {
                await editFolderName(userId, inputValue, folderInfo.folderId);
                rename = true;
            } else {
                if (folders.find((x) => x.newsletters.length === 0)) {
                    throw {
                        error: 'You can have one empty folder per time!',
                        close: true,
                    };
                }

                await addFolder(userId, inputValue);
            }
            setFolderInfo('');
            notifyUser(
                'success',
                rename
                    ? 'Successfully renamed folder!'
                    : 'Successfully created new folder!',
                setNotification
            );
            closeInputCreateFolder();
            setViewMoreFolders(false);
        } catch (error) {
            notifyUser('error', error.error || error.message, setNotification);
            if (error.close) {
                closeInputCreateFolder();
                setViewMoreFolders(true);
            }
        }
    };

    return (
        <form className='input-create-wrapper' onSubmit={handleSubmit}>
            <input
                placeholder='Folder name'
                name='folderName'
                onChange={(e) => setInputValue(e.target.value)}
                autoFocus
                value={inputValue}
            />
            <div className='input-buttons-create'>
                <button className='save-button' type='submit'>
                    Save
                </button>
                <button className='cancel-button' onClick={cancelCreate}>
                    Cancel
                </button>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
});
const mapDispatchToProps = {
    addFolder,
    closeInputCreateFolder,
    editFolderName,
};
export default connect(mapStateToProps, mapDispatchToProps)(InputCreateFolder);
