import { combineReducers } from 'redux';
import {
    FETCH_LATEST_NEWSLETTERS,
    FETCH_LATEST_NEWSLETTERS_INITIAL,
    FETCH_POPULAR_NEWSLETTERS,
    FILTER_LATEST_NEWSLETTERS_INITIAL,
    CLEAR_CURRENT_NEWSLETTER,
    FETCH_DAILY_NEWSLETTERS,
    FETCH_DAILY_NEWSLETTERS_IDS,
    FETCH_NEWSLETTER,
    FETCH_MORE_NEWSLETTER_ARTICLE,
    CLEAR_DAILY_NEWSLETTERS,
    FETCH_UNPUBLISHED_NEWSLETTERS,
    FETCH_PUBLISHED_NEWSLETTERS,
    EDIT_SINGLE_NEWSLETTER,
    EDIT_NEWSLETTERS_PRIVATE,
    CLEAR_PUBLISHED_NEWSLETTERS,
    MAKE_NEWSLETTER_PUBLIC,
    DELETE_UNPUBLISHED_NEWSLETTER,
    DELETE_PUBLISHED_NEWSLETTER,
    CLEAR_LATEST_NEWSLETTERS,
    CLEAR_POPULAR_NEWSLETTERS,
    UNPUBLISH_NEWSLETTER,
    FETCH_LATEST_FEED_NEWSLETTERS,
    FETCH_LATEST_FEED_SINGLE_NEWSLETTER,
    CLEAR_LATEST_FEED_NEWSLETTERS,
    FETCH_NEWSLETTER_INFO,
    CLEAR_CURRENT_NEWSLETTER_ARTICLES,
    FETCH_PRIVATE_NEWSLETTERS_USER,
    CLEAR_PRIVATE_NEWSLETTERS_USER
} from '../actionTypes/newsletterTypes';

const initialNewsletterState = {
    tags: [],
    articles: [
        {
            _id: '',
            date: '',
            title: '',
            content: '',
            clearContent: '',
            clearContentShort: '',
        },
    ],
    _id: '',
    logo: '',
    category: {
        _id: '',
        name: '',
    },
    name: '',
    description: '',
    email: '',
    lastArticleDate: '',
};

const current = (state = initialNewsletterState, action = {}) => {
    switch (action.type) {
        case CLEAR_CURRENT_NEWSLETTER:
            return initialNewsletterState;
        case CLEAR_CURRENT_NEWSLETTER_ARTICLES: 
            return {
                ...state,
                articles: []
            }    
        case FETCH_NEWSLETTER_INFO: 
            return {
                ...initialNewsletterState,
                ...action.payload,
                articles: []
            }    
        case FETCH_NEWSLETTER:
            return {
                ...initialNewsletterState,
                ...action.payload,
                articles: [...state.articles,...action.payload.articles].filter(a=>a._id)
            };
        case FETCH_MORE_NEWSLETTER_ARTICLE:
            return {
                ...state,
                articles: [...state.articles, ...action.payload],
            };
        default:
            return state;
    }
};

const popular = (state = [], action = {}) => {
    switch (action.type) {
        case FETCH_POPULAR_NEWSLETTERS:
            return action.payload.map((x) => ({
                ...initialNewsletterState,
                ...x,
            }));
        case CLEAR_POPULAR_NEWSLETTERS:
            return [];
        default:
            return state;
    }
};

const latest = (state = [], action = {}) => {
    switch (action.type) {
        case FETCH_LATEST_NEWSLETTERS:
            return state.concat(
                action.payload.map((x) => ({ ...initialNewsletterState, ...x }))
            );
        case FETCH_LATEST_NEWSLETTERS_INITIAL:
        case FILTER_LATEST_NEWSLETTERS_INITIAL:
            return action.payload.map((x) => ({
                ...initialNewsletterState,
                ...x,
            }));
        case CLEAR_LATEST_NEWSLETTERS:
            return [];
        default:
            return state;
    }
};

const handleEditPrivateNewsletters = (state, newsletter) => {
    return state.map((x) =>
        x._id === newsletter.id ? { ...x, ...newsletter } : x
    );
};

const unpublished = (state = [], action = {}) => {
    switch (action.type) {
        case EDIT_NEWSLETTERS_PRIVATE: 
        return handleEditPrivateNewsletters(state, action.payload);
        case FETCH_UNPUBLISHED_NEWSLETTERS:
            return action.payload.map((x) => ({
                ...initialNewsletterState,
                ...x,
            }));
        case MAKE_NEWSLETTER_PUBLIC:
        case DELETE_UNPUBLISHED_NEWSLETTER:
            return state.filter((u) => u._id !== action.payload);
        default:
            return state;
    }
};

const handleDailyNewsletters = (state, newsletters) => {
    const newState = [...state];
    newsletters.forEach((x) => newState.push(x));
    return newState;
};

const daily = (state = [], action = {}) => {
    switch (action.type) {
        case FETCH_DAILY_NEWSLETTERS:
            return handleDailyNewsletters(state, action.payload);
        case CLEAR_DAILY_NEWSLETTERS:
            return [];
        default:
            return state;
    }
};

const handleLatestFeedNewsletters = (state, newsletters) => {
    const newState = [...state];
    newsletters.forEach((x) => newState.push(x));
    return newState;
};


const handleLatestFeedSingleNewsletter = (state, newsletter) => {
    const newState = [...state];
    const object = newState.find(o=>o._id === newsletter._id);
    newsletter.articles.forEach((x) => object.articles.push(x));
    
    return newState;
};

const latestFeed = (state = [], action = {}) => {
    switch (action.type) {
        case FETCH_LATEST_FEED_NEWSLETTERS:
            return handleLatestFeedNewsletters(state, action.payload);
        case CLEAR_LATEST_FEED_NEWSLETTERS:
            return [];
        case FETCH_LATEST_FEED_SINGLE_NEWSLETTER:
            return handleLatestFeedSingleNewsletter(state,action.payload);
        default:
            return state;
    }
};

const personal = (state = [], action = {}) => {
    switch (action.type) {
        case FETCH_PRIVATE_NEWSLETTERS_USER:
            return action.payload.map(n=>({...initialNewsletterState,...n}));
        case CLEAR_PRIVATE_NEWSLETTERS_USER:
            return [];
        default:
            return state;
    }
};

const nextAndPreviousIds = (state = [], action) => {
    switch (action.type) {
        case FETCH_DAILY_NEWSLETTERS_IDS:
            return action.payload;
        default:
            return state;
    }
};

const handleEditNewsletter = (state, newsletter) => {
    return state.map((x) =>
        x._id === newsletter.id ? { ...x, ...newsletter } : x
    );
};

const published = (state = [], action) => {
    switch (action.type) {
        case FETCH_PUBLISHED_NEWSLETTERS:
            return action.payload;
        case EDIT_SINGLE_NEWSLETTER:
            return handleEditNewsletter(state, action.payload);
        case DELETE_PUBLISHED_NEWSLETTER:
            return state.filter((p) => p._id !== action.payload);
        case CLEAR_PUBLISHED_NEWSLETTERS:
            return [];
        case UNPUBLISH_NEWSLETTER:
            return state.filter((p) => p._id !== action.payload);
        default:
            return state;
    }
};
export default combineReducers({
    latest,
    popular,
    daily,
    latestFeed,
    unpublished,
    current,
    published,
    personal,
    nextAndPreviousIds,
});

export const getLatestNewsletters = (state) => state.newsletters.latest;
export const getPopularNewsletters = (state) => state.newsletters.popular;
export const getDailyNewsletters = (state) => state.newsletters.daily;
export const getLatestFeedNewsletters = (state) => state.newsletters.latestFeed;
export const getCurrentNewsletter = (state) => state.newsletters.current;
export const getLatestCount = (state) => state.newsletters.latest.length;
export const getUnpublishedNewsletters = (state) =>
    state.newsletters.unpublished;
export const getAllPublished = (state) => state.newsletters.published;
export const getNextAndPreviousIds = (state) => state.newsletters.nextAndPreviousIds;
export const getPersonal = (state) => state.newsletters.personal;
