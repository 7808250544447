import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import "./LoaderMaterialUI.scss";

export default function LoaderMaterialUI() {
    return (
        <div className='loader-material-ui'>
            <LinearProgress />
        </div>
    );
}
