import { ReactComponent as FavoriteIcon } from './assets/favorite-icon.svg';
import { ReactComponent as FavoriteIconFill } from './assets/favorite-icon-fill.svg';
import { ReactComponent as ArrowUp } from './assets/arrow-up-icon.svg';
import { ReactComponent as ArrowDown } from './assets/arrow-down-icon.svg';
import { ReactComponent as GoBackIcon } from './assets/goBack-icon.svg';
import ReadIcon from './assets/ReadIcon';
import {
    getUserId,
    getUserFavorites,
    getUserReadArticles,
    getUserNewslettersArticles,
    getUserFavoritesArticlesIds,
    getUserUnseen,
    getUserRole,
} from '../../../reducers/userReducer';
import { getNextAndPreviousIds } from '../../../reducers/newsletterReducer';
import {
    getArticle,
    getLastEntrance,
    getNextArticle,
} from '../../../reducers/articleReducer';
import {
    addArticleToFavorites,
    removeArticleFromFavorites,
    addArticleToRead,
    removeArticleFromRead,
    removeFromUnseen,
} from '../../../actions/userActions';
import {
    clearFavoritesArticles,
    fetchSingleArticle,
    removeArticleSuccess,
    fetchNextArticle,
    removeNextArticleSuccess,
} from '../../../actions/articleActions';
import { connect } from 'react-redux';
import LayoutMain from '../../layouts/LayoutMain';
import ArticleContent from './ArticleContent';
import ArticleHiddenNext from './ArticleHiddenNext';
import ArticleNext from './ArticleNext';
import { useState, useEffect } from 'react';
import { hourArticleProviderShort } from '../../../helpers/dateProvider';
import { takeProgress } from '../../../helpers/scrollHandler';
import { showAlternativeLogo } from '../../../helpers/showAlternativeLogo';
import { clearDailyNewsletters } from '../../../actions/newsletterActions';
import { useLocation, Link } from 'react-router-dom';
import { sendAmplitudeData } from '../../../utils/amplitude';
import { useHistory } from 'react-router-dom';
import { getFromLS } from '../../../utils/storage';
import './ArticleReadEmbedd.scss';
import LoaderMaterialUI from '../../shared/LoaderMaterialUI';

const ArticleReadEmbedd = ({
    userId,
    favorites,
    match,
    arrowArticlesDaily,
    addArticleToRead,
    removeArticleFromFavorites,
    addArticleToFavorites,
    fetchSingleArticle,
    article,
    removeArticleSuccess,
    clearDailyNewsletters,
    clearFavoritesArticles,
    arrowArticlesAll,
    arrowArticleFavorites,
    read,
    fetchNextArticle,
    removeNextArticleSuccess,
    nextArticle,
    removeArticleFromRead,
    userUnseen,
    removeFromUnseen,
    lastEntrance,
    userRole,
}) => {
    const [bottom, setBottom] = useState(false);
    const [dashOffset, setDashOffset] = useState(0);
    const [stroke, setStroke] = useState(false);
    const history = useHistory();

    if (!getFromLS('role')) {
        history.push('/');
    }

    const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
    );

    // determine location for next article
    const location = useLocation()
        .pathname.split('/')
        .filter((a) => a.trim() !== '');
    const page =
        location[0] === 'latest'
            ? 'latest'
            : location[0] === 'favorites'
            ? 'favorites'
            : '';

    // find next article logic
    const id = match.params.id;
    let allArticles =
        page === 'latest'
            ? arrowArticlesDaily
            : page === 'favorites'
            ? arrowArticleFavorites
            : arrowArticlesAll;
    let allArticlesLength = allArticles.length;
    let index = allArticles.indexOf(id);
    let previous = index <= 0 ? allArticlesLength - 1 : index - 1;
    let next = index === allArticlesLength - 1 || index < 0 ? 0 : index + 1;

    useEffect(() => {
        setBottom(false);
        clearDailyNewsletters();
        clearFavoritesArticles();
        fetchSingleArticle(id);

        if (allArticles.length) {
            fetchNextArticle(allArticles[next]);
        }

        if (vw <= 839) {
            setBottom(true);
        } else {
            window.onscroll = function (ev) {
                if (
                    window.innerHeight + window.scrollY >=
                    document.body.offsetHeight
                ) {
                    setBottom(true);
                }
            };
        }

        return () => {
            removeArticleSuccess();
            removeNextArticleSuccess();
        };
    }, [
        removeArticleFromRead,
        addArticleToRead,
        removeArticleFromFavorites,
        addArticleToFavorites,
        fetchSingleArticle,
        fetchNextArticle,
        removeArticleSuccess,
        id,
    ]);

    useEffect(() => {
        if (userId && userUnseen.includes(id)) {
            removeFromUnseen({ userId, articleId: id });
        }
    }, [userUnseen, userId, id, removeFromUnseen]);

    useEffect(() => {
        if (userId) {
            addArticleToRead(userId, id);
        }
    }, [userId, id]);

    const onBottomReachScrollHandler = (e) => {
        let [progress] = takeProgress();

        if (progress > 0) {
            setStroke(true);
        }
        setDashOffset(progress);
    };

    useEffect(() => {
        window.addEventListener('scroll', onBottomReachScrollHandler);

        return () => {
            window.removeEventListener('scroll', onBottomReachScrollHandler);
        };
    }, [id]);

    if (allArticles.length && !nextArticle._id) {
        allArticles =
            page === 'latest'
                ? arrowArticlesDaily
                : page === 'favorites'
                ? arrowArticleFavorites
                : arrowArticlesAll;
        allArticlesLength = allArticles.length;
        index = allArticles.indexOf(id);
        previous = index <= 0 ? allArticlesLength - 1 : index - 1;
        next = index === allArticlesLength - 1 || index < 0 ? 0 : index + 1;
        fetchNextArticle(allArticles[next]);
    }

    const addToFavorites = () => {
        addArticleToFavorites(userId, id);
    };

    const removeFromFavorites = () => {
        removeArticleFromFavorites(userId, id);
    };

    const handlePrevious = () => {
        window.scroll(0, 0);
        setBottom(false);
        //analytics
        sendAmplitudeData('Navigate Article', {
            direction: 'prev',
        });
    };

    const handleNext = () => {
        window.scroll(0, 0);
        setBottom(false);
        //analytics
        sendAmplitudeData('Navigate Article', {
            direction: 'forw',
        });
    };

    return (
        // <LayoutMain backLink={true}>

        <div className='single-article-wrapper'>
            <div className='single-article-sticky-bar'>
                <div className='single-article-sticky-bar-button'>
                    <Link to={lastEntrance} className='go-back-link'>
                        <GoBackIcon />
                    </Link>
                    <div className='single-article-actions'>
                        <div className='article-read-icon'>
                            {allArticlesLength > 0 ? (
                                <>
                                    <Link
                                        className='link-to-article'
                                        to={
                                            page
                                                ? `/${page}/articles/${allArticles[previous]}`
                                                : `/articles/${allArticles[previous]}`
                                        }
                                        onClick={handlePrevious}
                                    >
                                        <ArrowDown className='icon-action arrow-action' />
                                    </Link>
                                    <Link
                                        className='link-to-article'
                                        to={
                                            page
                                                ? `/${page}/articles/${allArticles[next]}`
                                                : `/articles/${allArticles[next]}`
                                        }
                                        onClick={handleNext}
                                    >
                                        <ArrowUp className='icon-action arrow-action' />
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <ArrowDown className='icon-action arrow-action-disabled' />
                                    <ArrowUp className='icon-action arrow-action-disabled' />
                                </>
                            )}
                            {favorites.find((f) => f.article === id) ? (
                                <FavoriteIconFill
                                    className='favorite-icon-fill'
                                    onClick={removeFromFavorites}
                                />
                            ) : (
                                <FavoriteIcon onClick={addToFavorites} />
                            )}
                            <ReadIcon dashOffset={dashOffset} stroke={stroke} />
                        </div>
                    </div>
                </div>
            </div>
            {article._id ? (
                <>
                    <div className='single-article-title'>
                        <h1>{article.title}</h1>
                    </div>

                    <div className='single-article-info-actions'>
                        <div className='single-article-info'>
                            <Link
                                className='single-article-publisher-logo'
                                to={`/newsletters/${article.newsletter._id}`}
                            >
                                {article.newsletter.logo && (
                                    <img
                                        src={article.newsletter.logo}
                                        alt='N'
                                        onError={showAlternativeLogo}
                                    />
                                )}
                            </Link>
                            <div className='single-article-publisher-time'>
                                <Link
                                    to={`/newsletters/${article.newsletter._id}`}
                                >
                                    {article.newsletter.name}
                                </Link>
                                {article._id && (
                                    <p>
                                        {hourArticleProviderShort(article.date)}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='single-article-content'>
                        {article._id && (
                            <ArticleContent
                                newsletterName={article.newsletter.name}
                                content={article.content}
                                id={article._id}
                                userRole={userRole}
                            />
                        )}
                    </div>
                    {article.title && allArticles.length && !bottom ? (
                        <>
                            <ArticleHiddenNext
                                newsletterName={nextArticle.newsletter.name}
                                newsletterLogo={nextArticle.newsletter.logo}
                                newsletterId={nextArticle.newsletter._id}
                                time={hourArticleProviderShort(
                                    nextArticle.date
                                )}
                                title={nextArticle.title}
                                url={
                                    page
                                        ? `/${page}/articles/${allArticles[next]}`
                                        : `/articles/${allArticles[next]}`
                                }
                            />
                            <div className='first-bottom'></div>
                        </>
                    ) : null}
                    {article.title && allArticles.length && bottom ? (
                        <ArticleNext
                            newsletterName={nextArticle.newsletter.name}
                            newsletterLogo={nextArticle.newsletter.logo}
                            newsletterId={nextArticle.newsletter._id}
                            time={hourArticleProviderShort(nextArticle.date)}
                            title={nextArticle.title}
                            content={nextArticle.content}
                            url={
                                page
                                    ? `/${page}/articles/${allArticles[next]}`
                                    : `/articles/${allArticles[next]}`
                            }
                        />
                    ) : null}
                </>
            ) : (
                <LoaderMaterialUI />
            )}
        </div>
        // </LayoutMain>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    userRole: getUserRole(state),
    favorites: getUserFavorites(state),
    read: getUserReadArticles(state),
    article: getArticle(state),
    nextArticle: getNextArticle(state),
    arrowArticlesDaily: getNextAndPreviousIds(state),
    arrowArticlesAll: getUserNewslettersArticles(state),
    arrowArticleFavorites: getUserFavoritesArticlesIds(state),
    userUnseen: getUserUnseen(state),
    lastEntrance: getLastEntrance(state),
});

const mapDispatchToProps = {
    addArticleToFavorites,
    removeArticleFromFavorites,
    addArticleToRead,
    fetchSingleArticle,
    removeArticleSuccess,
    clearDailyNewsletters,
    clearFavoritesArticles,
    fetchNextArticle,
    removeNextArticleSuccess,
    removeArticleFromRead,
    removeFromUnseen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleReadEmbedd);
