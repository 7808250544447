import './ChosenNewslettersMenu.scss';

const ChosenNewslettersMenu = ({ userNewsletters }) => {
    return (
        <div className='chosen-newsletters-menu-wrapper'>
            <span>
                Start off by subscribing to some of our favorite newsletters
            </span>
        </div>
    );
};

export default ChosenNewslettersMenu;
