import { NavLink, useLocation } from 'react-router-dom';
import './Category.scss';

const Category = ({ textContent }) => {
    const [_, mainPath, subPath] = useLocation().pathname.split('/');
    const textContentToLower = textContent.split(' ').join('-').toLowerCase();
    let isCategory = subPath === textContentToLower;

    return (
        <NavLink
            activeClassName='selected'
            to={
                isCategory
                    ? `/${mainPath}`
                    : `/${mainPath}/${textContentToLower}`
            }
        >
            <li className='category-box-wrapper'>
                <span>{textContent}</span>
            </li>
        </NavLink>
    );
};

export default Category;
