import {
    FETCH_FAVORITE_ARTICLES,
    FETCH_SINGLE_ARTICLE,
    REMOVE_SINGLE_ARTICLE,
    CLEAR_FAVORITES_ARTICLE,
    LAST_ENTRANCE,
    FETCH_NEXT_ARTICLE,
    REMOVE_NEXT_ARTICLE,
    FETCH_SEARCH_CONTENT_MATCH,
    CLEAR_SEARCH_CONTENT_MATCH,
} from '../actionTypes/articleTypes';
import articleService from '../services/articleService';

const fetchSingleArticleSuccess = (data) => ({
    type: FETCH_SINGLE_ARTICLE,
    payload: data,
});

const fetchNextArticleSuccess = (data) => ({
    type: FETCH_NEXT_ARTICLE,
    payload: data,
});

const fetchFavoriteArticlesSuccess = (data) => ({
    type: FETCH_FAVORITE_ARTICLES,
    payload: data,
});

export const fetchSearchContentMatchSuccess = (data) => ({
    type: FETCH_SEARCH_CONTENT_MATCH,
    payload: data,
});

export const clearSearchContentMatch = () => ({
    type: CLEAR_SEARCH_CONTENT_MATCH,
});

export const lastEntranceSuccess = (url) => ({
    type: LAST_ENTRANCE,
    payload: url,
});

export const removeArticleSuccess = () => ({
    type: REMOVE_SINGLE_ARTICLE,
});

export const removeNextArticleSuccess = () => ({
    type: REMOVE_NEXT_ARTICLE,
});

export const clearFavoritesArticles = () => ({
    type: CLEAR_FAVORITES_ARTICLE,
});

export const fetchFavoriteArticles = (userId, page) => async (dispatch) => {
    try {
        const response = await articleService.findFavoriteArticles(
            userId,
            page
        );
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchFavoriteArticlesSuccess(data));
    } catch (error) {
        console.log(error);
    }
};

export const fetchSearchContentMatch = (userId, query, page) => async (dispatch) => {
    try {
        const response = await articleService.findSearchContentMatch(userId, query, page);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchSearchContentMatchSuccess(data));
    } catch (error) {
        console.log(error);
    }
};

export const fetchSearchContentMatchCount = (userId, query) => async (dispatch) => {
    try {
        const response = await articleService.findSearchContentMatchCount(userId, query);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        return data.count
    } catch (error) {
        console.log(error);
    }
};


export const fetchSingleArticle = (articleId) => async (dispatch) => {
    const response = await articleService.findSingleArticle(articleId);
    const data = await response.json();

    if (data.error) {
        throw { error: data.error };
    }

    dispatch(fetchSingleArticleSuccess(data));
};

export const fetchNextArticle = (articleId) => async (dispatch) => {
    const response = await articleService.findSingleArticle(articleId);
    const data = await response.json();

    if (data.error) {
        throw { error: data.error };
    }

    dispatch(fetchNextArticleSuccess(data));
};

export const createNewArticle = (article) => async () => {
    try {
        const response = await articleService.createNewArticle(article);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }
    } catch (err) {
        throw { error: err.error };
    }
};

export const uploadArticleFromUser = (userId, article) => async () => {
    try {
        const response = await articleService.uploadArticleFromUser(
            userId,
            article
        );
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }
    } catch (err) {
        throw { error: err.error };
    }
};
