import {
    ADD_USER_FOLDER,
    LOAD_USER_FOLDERS,
    ADD_NEWSLETTER_TO_FOLDER,
    REMOVE_NEWSLETTER_FROM_FOLDER,
    EDIT_FOLDER_NAME,
    DELETE_USER_FOLDER,
    LOAD_SINGLE_FOLDER,
    MAKE_FOLDER_PUBLIC,
    MAKE_FOLDER_PRIVATE,
    CHANGE_POSITIONS_NEWSLETTERS,
    LOAD_USER_NEWSLETTERS_NOT_IN_FOLDERS,
    RETURN_NEWSLETTER_TO_MAIN_FOLDER,
    ADD_NEWSLETTER_TO_ANOTHER_FOLDER,
    CHANGE_POSITIONS_NEWSLETTERS_FOLDER,
} from '../actionTypes/userTypes';
import folderService from '../services/folderService';
import { sendAmplitudeData } from '../utils/amplitude';

export const addFolderSuccess = (folder) => ({
    type: ADD_USER_FOLDER,
    payload: folder,
});

export const fetchUsersFoldersSuccess = (folders) => ({
    type: LOAD_USER_FOLDERS,
    payload: folders,
});

export const changePositionsInFolderSuccess = (data) => ({
    type: CHANGE_POSITIONS_NEWSLETTERS_FOLDER,
    payload: data,
});

export const removeNewsletterFromFolderSuccess = (newsletterId) => ({
    type: REMOVE_NEWSLETTER_FROM_FOLDER,
    payload: newsletterId,
});

export const addNewsletterToUsersFolderSuccess = (folder) => ({
    type: ADD_NEWSLETTER_TO_FOLDER,
    payload: folder,
});
export const addNewsletterToAnotherFolderSuccess = (data) => ({
    type: ADD_NEWSLETTER_TO_ANOTHER_FOLDER,
    payload: data,
});
export const returnNewsletterToMainFolderSuccess = (data) => ({
    type: RETURN_NEWSLETTER_TO_MAIN_FOLDER,
    payload: data,
});
export const editFolderNameSuccess = (folder) => ({
    type: EDIT_FOLDER_NAME,
    payload: folder,
});

export const deleteFolderSuccess = (folder) => ({
    type: DELETE_USER_FOLDER,
    payload: folder,
});

export const fetchSingleFolderSuccess = (folder) => ({
    type: LOAD_SINGLE_FOLDER,
    payload: folder,
});

export const makeFolderPublicSuccess = (folderId) => ({
    type: MAKE_FOLDER_PUBLIC,
    payload: folderId,
});

export const makeFolderPrivateSuccess = (folderId) => ({
    type: MAKE_FOLDER_PRIVATE,
    payload: folderId,
});

export const fetchNewslettersNotInFolderSuccess = () => ({
    type: LOAD_USER_NEWSLETTERS_NOT_IN_FOLDERS,
});

export const addFolder = (userId, name) => async (dispatch) => {
    try {
        const res = await folderService.addFolder(userId, name);
        const data = await res.json();
        if (data.error) {
            throw { error: data.error };
        }

        //analytics
        sendAmplitudeData('New Folder', {
            folder_name: name,
            folder_id: data._id,
        });

        dispatch(addFolderSuccess(data));
    } catch (error) {
        throw new Error(error.error);
    }
};

export const fetchUsersFolders = (id) => async (dispatch) => {
    try {
        const res = await folderService.findFolders(id);
        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }

        await dispatch(fetchUsersFoldersSuccess(data));
    } catch (error) {
        console.log(error);
    }
};

export const addNewsletterToUsersFolder =
    (userId, newsletter, folderName, folderId, sourceFolderId) =>
    async (dispatch) => {
        try {
            dispatch(
                addNewsletterToUsersFolderSuccess({
                    ...newsletter,
                    folderName,
                    sourceFolderId,
                })
            );

            const res = await folderService.addNewsletterToUsersFolder(
                userId,
                newsletter._id,
                folderId,
                sourceFolderId
            );
            const data = await res.json();

            if (data.error) {
                throw { error: data.error };
            }
        } catch (error) {
            throw { error };
        }
    };

export const addNewsletterToAnotherFolder =
    (userId, folderId, newsletterId, destinationFolderId) =>
    async (dispatch) => {
        dispatch(
            addNewsletterToAnotherFolderSuccess({
                folderId,
                newsletterId,
                destinationFolderId,
            })
        );

        try {
            const res = await folderService.addNewsletterToAnotherFolder(
                userId,
                folderId,
                newsletterId,
                destinationFolderId
            );
            const data = await res.json();

            if (data.error) {
                throw { error: data.error };
            }
        } catch (error) {
            throw { error };
        }
    };

export const returnNewsletterToMainFolder =
    (folderId, newsletterId, userId, position) => async (dispatch) => {
        dispatch(
            returnNewsletterToMainFolderSuccess({
                folderId,
                newsletterId,
                position,
            })
        );

        try {
            const res = await folderService.returnNewsletterToMainFolder(
                folderId,
                newsletterId,
                userId,
                position
            );
            const data = await res.json();

            if (data.error) {
                throw { error: data.error };
            }
        } catch (error) {
            throw { error };
        }
    };

export const changePositionsInFolder =
    (userId, folderId, newsletterId, destinationIndex, sourceIndex) =>
    async (dispatch) => {
        dispatch(
            changePositionsInFolderSuccess({
                folderId,
                newsletterId,
                destinationIndex,
                sourceIndex,
            })
        );

        try {
            const res = await folderService.changePositionsInFolder(
                userId,
                folderId,
                newsletterId,
                destinationIndex
            );
            const data = await res.json();

            if (data.error) {
                throw { error: data.error };
            }
        } catch (error) {
            throw { error };
        }
    };

export const fetchCurrentFolderNewsletters = (id) => async (dispatch) => {
    try {
        const res = await folderService.findNewslettersForFolder(id);
        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }
        return data;
        // maybe should be added to the redux state but its about discussion
    } catch (error) {
        throw { error };
    }
};

export const editFolderName =
    (userId, folderName, folderId) => async (dispatch) => {
        try {
            const res = await folderService.editFolderName(
                userId,
                folderName,
                folderId
            );
            const data = await res.json();
            if (data.error) {
                throw { error: data.error };
            }

            dispatch(editFolderNameSuccess({ folderId, folderName }));
        } catch (error) {
            throw new Error(error.error);
        }
    };

export const deleteFolder = (userId, folderId) => async (dispatch) => {
    try {
        const res = await folderService.deleteFolder(userId, folderId);
        const data = await res.json();
        if (data.error) {
            throw { error: data.error };
        }

        dispatch(deleteFolderSuccess({ folderId }));
        // dispatch(fetchNewslettersNotInFolderSuccess());
    } catch (error) {
        throw new Error(error.error);
    }
};

export const fetchSingleFolder = (folderId) => async (dispatch) => {
    try {
        const res = await folderService.fetchSingleFolder(folderId);
        const data = await res.json();
        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchSingleFolderSuccess(data));
    } catch (error) {
        console.log(error);
    }
};

export const makeFolderPublic = (userId, folderId) => async (dispatch) => {
    try {
        const res = await folderService.makeFolderPublic(userId, folderId);
        const data = await res.json();
        if (data.error) {
            throw { error: data.error };
        }

        dispatch(makeFolderPublicSuccess(folderId));
    } catch (error) {
        console.log(error);
    }
};

export const makeFolderPrivate = (userId, folderId) => async (dispatch) => {
    try {
        const res = await folderService.makeFolderPrivate(userId, folderId);
        const data = await res.json();
        if (data.error) {
            throw { error: data.error };
        }
        dispatch(makeFolderPrivateSuccess(folderId));
    } catch (error) {
        console.log(error);
    }
};
