import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LayoutMain from '../../layouts/LayoutMain';
import MainHeading from '../../shared/MainHeading';
import { fetchSingleFolder } from '../../../actions/folderActions';
import {
    getCurrentFolder,
    getUserId,
    getUserNewsletters,
} from '../../../reducers/userReducer';
import { connect } from 'react-redux';
import ExploreCard from '../../shared/ExploreCard';
import {
    subscribeToAllNewslettersFromFolder,
    fetchNameOfUser,
} from '../../../actions/userActions';
import { ReactComponent as ArrowIcon } from './assets/arrowIcon.svg';
import { ReactComponent as DefaultProfilePicture } from './assets/default-profile-picture.svg';
import PublicFolderModalGuest from '../../shared/PublicFolderModalGuest';
import './PublicFolder.scss';

const PublicFolder = ({
    fetchSingleFolder,
    folder,
    userId,
    subscribeToAllNewslettersFromFolder,
    userNewsletters,
    fetchNameOfUser,
}) => {
    const folderId = window.location.pathname.split('/').pop();
    const [subscribedToAll, setSubscribedToAll] = useState(false);
    const [modal, setModal] = useState(false);
    const [username, setUsername] = useState('');
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const name = await fetchNameOfUser(folder.user?._id);
                setUsername(name);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();

        Object.keys(folder).length === 0 && fetchSingleFolder(folderId);
        if (Object.keys(folder).length !== 0) {
            if (folder.message) {
                history.push('/explore');
                return;
            }

            const userNewslettersIds = userNewsletters.map((x) => x._id);
            const check = folder.newsletters
                .filter((n) => n.isPublic)
                .filter((x) => {
                    if (!userNewslettersIds.includes(x._id)) {
                        return x;
                    }
                });
            check.length === 0
                ? setSubscribedToAll(true)
                : setSubscribedToAll(false);
        }
    }, [fetchSingleFolder, folderId, userNewsletters, userId, folder]);

    const handleSubscribeToAll = async () => {
        try {
            if (!userId) {
                setModal(true);
            } else {
                const userNewslettersIds = userNewsletters.map((x) => x._id);
                const newslettersIdsToSend = folder.newsletters
                    .filter((n) => n.isPublic)
                    .filter((x) => {
                        if (!userNewslettersIds.includes(x._id)) {
                            return x;
                        }
                    })
                    .map((n) => n._id);

                await subscribeToAllNewslettersFromFolder(
                    userId,
                    newslettersIdsToSend
                );
                setSubscribedToAll(true);
            }
        } catch (error) {}
    };

    return (
        <>
            {/* <LayoutMain> */}
            {modal && <PublicFolderModalGuest setModal={setModal} />}
            <div className='public-folder-page-wrapper'>
                <div className='public-folder-name-wrapper'>
                    <h1>{username}</h1>
                </div>
                <div className='public-folder-page'>
                    <div className='public-folder-image-wrapper'>
                        {folder.user ? (
                            folder.user.photoURL ? (
                                <div className='user-info-image-public-folder'>
                                    <img
                                        src={folder.user.photoURL}
                                        alt={username}
                                    />
                                </div>
                            ) : (
                                <div className='user-info-image-public-folder-default'>
                                    <DefaultProfilePicture />
                                </div>
                            )
                        ) : null}
                    </div>
                    <div className='public-folder-info-wrapper'>
                        <div className='public-folder-info'>
                            <div className='public-folder-description-info'>
                                <h4>
                                    Below the fold refers to the section of a
                                    web page that is only visible after
                                    scrolling down. The digital marketing
                                    conventional wisdom is that above the fold.
                                </h4>
                                <button
                                    onClick={handleSubscribeToAll}
                                    disabled={subscribedToAll}
                                >
                                    <span>Subscribe</span>
                                    <span>
                                        <ArrowIcon />
                                    </span>
                                </button>
                            </div>
                            <div className='public-folder-explore-cards-wrapper'>
                                {folder.newsletters?.map((x) => (
                                    <ExploreCard
                                        category={'News'}
                                        newsletter={x}
                                        description={x.description}
                                        articles={x.articles}
                                        id={x._id}
                                        key={x._id}
                                        setModal={setModal}
                                        isPublic={x.isPublic}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </LayoutMain> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    folder: getCurrentFolder(state),
    userId: getUserId(state),
    userNewsletters: getUserNewsletters(state),
});
const mapDispatchToProps = {
    fetchSingleFolder,
    subscribeToAllNewslettersFromFolder,
    fetchNameOfUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(PublicFolder);
