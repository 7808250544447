import { useState } from 'react';
import { connect } from 'react-redux';

import {
    getUserFolders,
    getUserId,
} from '../../../../../../reducers/userReducer';
import { addNewsletterToUsersFolder } from '../../../../../../actions/folderActions';
import { removeNewsletterFromNewsletterNotInFolder } from '../../../../../../actions/newsletterActions';

import { setFolderAbbriviation } from '../../../../../../helpers/setFolderAbbriviation';
import { notifyUser } from '../../../../../../helpers/notifications';

import { ReactComponent as MoveArrowRightIcon } from './assets/move-right-arrow-icon.svg';
import './ModalMoveToFolder.scss';

const ModalMoveToFolder = ({
    setModal,
    newsletter,
    userFolders,
    userId,
    addNewsletterToUsersFolder,
    removeNewsletterFromNewsletterNotInFolder,
    setNotification,
    setHoverable,
    folder,
}) => {
    const [selectedFolder, setSelectedFolder] = useState({ name: '', id: '' });
    const foldersToUse = folder
        ? userFolders.filter((f) => f._id !== folder._id)
        : userFolders;

    const onCloseClickHandler = () => {
        setModal(false);
        setHoverable(true);
    };

    const onFolderClickHandler = (e) => {
        setSelectedFolder({ ...e.currentTarget.dataset });
    };

    const onMoveToClickHandler = async () => {
        try {
            await addNewsletterToUsersFolder(
                userId,
                newsletter,
                selectedFolder.name,
                selectedFolder.id,
                folder ? folder._id : null
            );

            if (folder) {
                await removeNewsletterFromNewsletterNotInFolder(
                    userId,
                    newsletter._id
                );
            }

            setModal(false);
            setHoverable(true);
            notifyUser(
                'success',
                'Added newsletter to folder!',
                setNotification
            );
        } catch (error) {
            notifyUser('error', error?.error?.error, setNotification);
        }
    };

    return (
        <div className='modal-move-to-folder-wrapper'>
            <div className='modal-move-to-folder'>
                <div
                    className='modal-button-close-move-to-folder'
                    onClick={onCloseClickHandler}
                >
                    <p>Close</p>
                </div>
                <div className='modal-main-content-move-to-folder'>
                    <h4 className='modal-title-move-to-folder'>
                        Move {newsletter.name} to folder
                    </h4>
                    <div className='modal-folders-wrapper'>
                        <ul className='modal-folders-list'>
                            {foldersToUse.map((x) => (
                                <li
                                    className={`modal-single-folder${
                                        selectedFolder.id === x._id ||
                                        selectedFolder.id === ''
                                            ? ' selected'
                                            : ''
                                    }`}
                                    key={x._id}
                                    onClick={onFolderClickHandler}
                                    data-id={x._id}
                                    data-name={x.name}
                                >
                                    <div
                                        className={`folder-initials-icon${
                                            x.isPublic ? ' public' : ''
                                        } `}
                                    >
                                        <span>
                                            {setFolderAbbriviation(x.name)}
                                        </span>
                                    </div>
                                    <h4>{x.name}</h4>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {selectedFolder.name && (
                        <button
                            className='move-to-folder-btn'
                            onClick={onMoveToClickHandler}
                        >
                            <span className='move-to-folder-name'>
                                Move to {selectedFolder.name}
                            </span>
                            <MoveArrowRightIcon />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userFolders: getUserFolders(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    addNewsletterToUsersFolder,
    removeNewsletterFromNewsletterNotInFolder,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalMoveToFolder);
