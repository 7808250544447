import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { textCutterForFoldersAndNewslettrsInFolders } from '../../../../../../helpers/textCutter';
import { showAlternativeLogo } from '../../../../../../helpers/showAlternativeLogo';
import { countUserUnreadArticles } from '../../../../../../helpers/userProvider';
import { getOpenOrClosed } from '../../../../../../reducers/sideMenuReducer';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as OrangePointUnread } from '../../../assets/orange-point-unread-icon.svg';
import { ReactComponent as OptionsFolderIcon } from '../../assets/OptionsFolder.svg';
import NewsletterOptions from '../../NewsLettersInFolderNewsletters/NewsletterOptions';
import MoveToFolderModal from '../../NewsLettersInFolderNewsletters/ModalMoveToFolder';
import { Link, useHistory } from 'react-router-dom';
import '../NewslettersInFolderRegular.scss';
import {
    getUsername,
    getUserNewsletters,
    getUserRead,
    getUserUnseen,
} from '../../../../../../reducers/userReducer';
import { getArticle } from '../../../../../../reducers/articleReducer';

const NewslettersInFolderRegularNewsletters = ({
    handleCloseMenu,
    newsletter,
    read,
    userUnseen,
    userNewsletters,
    openOrClosed,
    isPublic,
    index,
    setNotification,
    setHoverable,
    hoverable,
    folder,
    userUsername,
    currentNewsletter,
    setCurrentNewsletter,
    article,
}) => {
    const [newsletterOptions, setNewsletterOptions] = useState(false);
    const [newsletterToUse, setNewsletterToUsed] = useState(newsletter);
    const [positionYOptions, setPositionYOptions] = useState(0);
    const [modal, setModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const location = history.location.pathname
            .split('/')
            .filter((w) => w !== '');

        if (location.includes('newsletters')) {
            const id = location.pop();
            if (id === newsletter._id) {
                setCurrentNewsletter(newsletter._id);
            }
        } else if (article.newsletter._id === newsletter._id) {
            setCurrentNewsletter(newsletter._id);
        }

        //Take updated articles
        if (userNewsletters.length) {
            setNewsletterToUsed(
                userNewsletters.find((n) => n._id === newsletter._id)
            );
        }
    }, [
        history.location.pathname,
        userUnseen,
        openOrClosed,
        article,
        setCurrentNewsletter,
        currentNewsletter,
    ]);

    const onOptionsNewsletterHandleClick = (e) => {
        const cursorY = e.target.getBoundingClientRect().top;

        if (newsletterOptions) {
            setNewsletterOptions(false);
            setPositionYOptions(0);
        } else {
            e.currentTarget.focus();
            setNewsletterOptions(true);
            setPositionYOptions(cursorY + 25);
        }
    };

    const onOptionsNewsletterBlurHandler = (e) => {
        const time = setInterval(function () {
            setNewsletterOptions(false);
            clearInterval(time);
        }, 200);
    };

    return (
        <>
            {newsletterToUse ? (
                <Draggable
                    key={newsletter._id}
                    draggableId={newsletter._id}
                    index={index}
                    isDragDisabled={modal}
                >
                    {(provided, snapshot) => (
                        <div
                            className={`newsletter-in-folder-regular-newsletter ${
                                currentNewsletter === newsletter._id
                                    ? 'current-newsletter-entered'
                                    : ''
                            }${hoverable ? ' hoverable' : ''} ${
                                snapshot.isDragging ? ' dragging' : ''
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Link
                                to={'/newsletters/' + newsletterToUse._id}
                                onClick={handleCloseMenu}
                            >
                                <div className='newsletter-in-folder-wrapper'>
                                    <div className='icon-newsletter-folder'>
                                        <img
                                            src={newsletterToUse.logo}
                                            alt='logo'
                                            onError={showAlternativeLogo}
                                            className='mini-logo'
                                        />
                                    </div>
                                    <p>
                                        {textCutterForFoldersAndNewslettrsInFolders(
                                            newsletterToUse.name,
                                            11
                                        )}
                                    </p>
                                </div>
                            </Link>
                            <div className='newsletter-additional-info-regular-wrapper'>
                                <div
                                    tabIndex={index}
                                    className={`options-folder-icon-wrapper${
                                        newsletterOptions ? ' visible' : ''
                                    }`}
                                    onClick={onOptionsNewsletterHandleClick}
                                    onBlur={onOptionsNewsletterBlurHandler}
                                >
                                    <OptionsFolderIcon className='options-folder-icon' />
                                </div>
                                {countUserUnreadArticles(
                                    newsletterToUse.articles,
                                    read
                                ) > 0 && (
                                    <>
                                        <span className='change-opacity-newsletter'>
                                            {countUserUnreadArticles(
                                                newsletterToUse.articles,
                                                read
                                            ) >= 100
                                                ? '99+'
                                                : countUserUnreadArticles(
                                                      newsletterToUse.articles,
                                                      read
                                                  )}
                                        </span>
                                        {userUnseen.find((u) =>
                                            newsletterToUse.articles.includes(u)
                                        ) && (
                                            <OrangePointUnread className='orange-point-icon' />
                                        )}
                                    </>
                                )}
                            </div>
                            {newsletterOptions && (
                                <NewsletterOptions
                                    positionY={positionYOptions}
                                    newsletterId={newsletter._id}
                                    setModal={setModal}
                                    setNotification={setNotification}
                                    setHoverable={setHoverable}
                                    folder={folder}
                                    userUsername={userUsername}
                                    isPublic={isPublic}
                                />
                            )}
                        </div>
                    )}
                </Draggable>
            ) : null}
            {modal && (
                <MoveToFolderModal
                    setModal={setModal}
                    newsletter={newsletter}
                    setNotification={setNotification}
                    setHoverable={setHoverable}
                    folder={folder}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    read: getUserRead(state),
    userUnseen: getUserUnseen(state),
    userNewsletters: getUserNewsletters(state),
    openOrClosed: getOpenOrClosed(state),
    userUsername: getUsername(state),
    article: getArticle(state),
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewslettersInFolderRegularNewsletters);
