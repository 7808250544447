export const FETCH_NEWSLETTER = 'FETCH_NEWSLETTER';
export const CLEAR_CURRENT_NEWSLETTER = 'CLEAR_CURRENT_NEWSLETTER';
export const CLEAR_CURRENT_NEWSLETTER_ARTICLES = 'CLEAR_CURRENT_NEWSLETTER_ARTICLES';
export const FETCH_LATEST_NEWSLETTERS_INITIAL = 'FETCH_LATEST_NEWSLETTERS_INITIAL';
export const FETCH_LATEST_NEWSLETTERS = 'FETCH_LATEST_NEWSLETTERS';
export const FILTER_LATEST_NEWSLETTERS_INITIAL = 'FILTER_LATEST_NEWSLETTERS_INITIAL';
export const FETCH_POPULAR_NEWSLETTERS = 'FETCH_POPULAR_NEWSLETTERS';
export const FETCH_DAILY_NEWSLETTERS = 'FETCH_DAILY_NEWSLETTERS';
export const FETCH_DAILY_NEWSLETTERS_IDS = 'FETCH_DAILY_NEWSLETTERS_IDS';
export const FETCH_LATEST_FEED_NEWSLETTERS = 'FETCH_LATEST_FEED_NEWSLETTERS';
export const FETCH_LATEST_FEED_SINGLE_NEWSLETTER = 'FETCH_LATEST_FEED_SINGLE_NEWSLETTER';
export const CLEAR_LATEST_FEED_NEWSLETTERS = 'CLEAR_LATEST_FEED_NEWSLETTERS';
export const FETCH_MORE_NEWSLETTER_ARTICLE = 'FETCH_MORE_NEWSLETTER_ARTICLE';
export const CLEAR_DAILY_NEWSLETTERS = 'CLEAR_DAILY_NEWSLETTERS';
export const FETCH_UNPUBLISHED_NEWSLETTERS = 'FETCH_UNPUBLISHED_NEWSLETTERS';
export const FETCH_PUBLISHED_NEWSLETTERS = 'FETCH_PUBLISHED_NEWSLETTERS';
export const EDIT_SINGLE_NEWSLETTER = 'EDIT_SINGLE_NEWSLETTER';
export const EDIT_NEWSLETTERS_PRIVATE = 'EDIT_NEWSLETTERS_PRIVATE';
export const CLEAR_PUBLISHED_NEWSLETTERS = 'CLEAR_PUBLISHED_NEWSLETTERS';
export const MAKE_NEWSLETTER_PUBLIC = 'MAKE_NEWSLETTER_PUBLIC';
export const DELETE_UNPUBLISHED_NEWSLETTER = 'DELETE_UNPUBLISHED_NEWSLETTER';
export const DELETE_PUBLISHED_NEWSLETTER = 'DELETE_PUBLISHED_NEWSLETTER';
export const CLEAR_LATEST_NEWSLETTERS = 'CLEAR_LATEST_NEWSLETTERS';
export const CLEAR_POPULAR_NEWSLETTERS = 'CLEAR_POPULAR_NEWSLETTERS';
export const UNPUBLISH_NEWSLETTER = 'UNPUBLISH_NEWSLETTER';
export const FETCH_UNREAD_SPECIFIC_NEWSLETTER = 'FETCH_UNREAD_SPECIFIC_NEWSLETTER';
export const FETCH_NEWSLETTER_INFO = 'FETCH_NEWSLETTER_INFO';
export const FETCH_PRIVATE_NEWSLETTERS_USER = 'FETCH_PRIVATE_NEWSLETTERS_USER';
export const CLEAR_PRIVATE_NEWSLETTERS_USER = 'CLEAR_PRIVATE_NEWSLETTERS_USER';