import {
    FETCH_LATEST_NEWSLETTERS,
    FETCH_LATEST_NEWSLETTERS_INITIAL,
    FETCH_POPULAR_NEWSLETTERS,
    FILTER_LATEST_NEWSLETTERS_INITIAL,
    CLEAR_CURRENT_NEWSLETTER,
    FETCH_DAILY_NEWSLETTERS,
    FETCH_NEWSLETTER,
    FETCH_MORE_NEWSLETTER_ARTICLE,
    CLEAR_DAILY_NEWSLETTERS,
    FETCH_UNPUBLISHED_NEWSLETTERS,
    FETCH_PUBLISHED_NEWSLETTERS,
    EDIT_SINGLE_NEWSLETTER,
    CLEAR_PUBLISHED_NEWSLETTERS,
    DELETE_UNPUBLISHED_NEWSLETTER,
    MAKE_NEWSLETTER_PUBLIC,
    DELETE_PUBLISHED_NEWSLETTER,
    FETCH_DAILY_NEWSLETTERS_IDS,
    CLEAR_LATEST_NEWSLETTERS,
    UNPUBLISH_NEWSLETTER,
    CLEAR_POPULAR_NEWSLETTERS,
    FETCH_LATEST_FEED_NEWSLETTERS,
    CLEAR_LATEST_FEED_NEWSLETTERS,
    FETCH_LATEST_FEED_SINGLE_NEWSLETTER,
    FETCH_UNREAD_SPECIFIC_NEWSLETTER,
    FETCH_NEWSLETTER_INFO,
    CLEAR_CURRENT_NEWSLETTER_ARTICLES,
    EDIT_NEWSLETTERS_PRIVATE,
    FETCH_PRIVATE_NEWSLETTERS_USER,
    CLEAR_PRIVATE_NEWSLETTERS_USER
} from '../actionTypes/newsletterTypes';
import {
    LOAD_USER_NEWSLETTERS_NOT_IN_FOLDERS,
    CHANGE_POSITIONS_NEWSLETTERS,
} from '../actionTypes/userTypes';
import newsletterService from '../services/newslettersService';
import { dateProvider } from '../helpers/dateProvider';

const fetchLatestNewslettersSuccess = (data) => ({
    type: FETCH_LATEST_NEWSLETTERS,
    payload: data,
});

const fetchLatestFeedNewslettersSuccess = (data) => ({
    type: FETCH_LATEST_FEED_NEWSLETTERS,
    payload: data,
});

const fetchLatestFeedSingleNewsletterSuccess = (data) => ({
    type: FETCH_LATEST_FEED_SINGLE_NEWSLETTER,
    payload: data,
});

const fetchPopularNewslettersSuccess = (data) => ({
    type: FETCH_POPULAR_NEWSLETTERS,
    payload: data,
});

const filterLatestNewslettersInitialSuccess = (data) => ({
    type: FILTER_LATEST_NEWSLETTERS_INITIAL,
    payload: data,
});

const fetchLatestNewslettersInitialSuccess = (data) => ({
    type: FETCH_LATEST_NEWSLETTERS_INITIAL,
    payload: data,
});

const fetchDailyNewslettersSuccess = (data) => ({
    type: FETCH_DAILY_NEWSLETTERS,
    payload: data,
});

const fetchDailyNewslettersIdsSuccess = (data) => ({
    type: FETCH_DAILY_NEWSLETTERS_IDS,
    payload: data,
});

const fetchNewsletterByIdSuccess = (data) => ({
    type: FETCH_NEWSLETTER,
    payload: data,
});

const fetchAllUnpublishedNewslettersSuccess = (data) => ({
    type: FETCH_UNPUBLISHED_NEWSLETTERS,
    payload: data,
});

const deleteUnpublishedSuccess = (newsletterId) => ({
    type: DELETE_UNPUBLISHED_NEWSLETTER,
    payload: newsletterId,
});

const makeNewsletterPublicSuccess = (newsletterId) => ({
    type: MAKE_NEWSLETTER_PUBLIC,
    payload: newsletterId,
});

const fetchUnreadSpecificNewsletterSuccess = (data) => ({
    type: FETCH_UNREAD_SPECIFIC_NEWSLETTER,
    payload: data,
});

const fetchNewsletterInfoSuccess = (data) => ({
    type: FETCH_NEWSLETTER_INFO,
    payload: data,
});

const editNewslettersPrivateInfoSuccess = (newsletterInfo) => ({
    type: EDIT_NEWSLETTERS_PRIVATE,
    payload: newsletterInfo,
});

const fetchPrivateNewslettersUserSuccess = (data) => ({
    type: FETCH_PRIVATE_NEWSLETTERS_USER,
    payload: data,
});

export const clearPrivateNewslettersUser = () => ({
    type: CLEAR_PRIVATE_NEWSLETTERS_USER,
});
export const clearLatestFeedNewslettersSuccess = () => ({
    type: CLEAR_LATEST_FEED_NEWSLETTERS,
});

export const clearCurrentNewsletter = () => ({
    type: CLEAR_CURRENT_NEWSLETTER,
});
export const clearCurrentNewsletterArticles = () => ({
    type: CLEAR_CURRENT_NEWSLETTER_ARTICLES,
});

export const clearLatestNewsletters = () => ({
    type: CLEAR_LATEST_NEWSLETTERS,
});

export const clearPopularNewsletters = () => ({
    type: CLEAR_POPULAR_NEWSLETTERS,
});

export const fetchMoreNewsletterArticlesSuccess = (articles) => ({
    type: FETCH_MORE_NEWSLETTER_ARTICLE,
    payload: articles,
});

export const clearDailyNewsletters = () => ({
    type: CLEAR_DAILY_NEWSLETTERS,
});

export const fetchAllPublishedNewslettersSuccess = (newsletters) => ({
    type: FETCH_PUBLISHED_NEWSLETTERS,
    payload: newsletters,
});

export const editNewsletterInfoSuccess = (newsletterInfo) => ({
    type: EDIT_SINGLE_NEWSLETTER,
    payload: newsletterInfo,
});

export const clearPublishedNewsletters = () => ({
    type: CLEAR_PUBLISHED_NEWSLETTERS,
});

export const deletePublishedNewsletterSuccess = (newsletterId) => ({
    type: DELETE_PUBLISHED_NEWSLETTER,
    payload: newsletterId,
});

export const unpublishNewsletterSuccess = (newsletterId) => ({
    type: UNPUBLISH_NEWSLETTER,
    payload: newsletterId,
});
export const changePositionsOfNewslettersSuccess = (startIndex, endIndex) => ({
    type: CHANGE_POSITIONS_NEWSLETTERS,
    payload: { startIndex, endIndex },
});

export const fetchNewslettersNotInFolderSuccess = (data) => ({
    type: LOAD_USER_NEWSLETTERS_NOT_IN_FOLDERS,
    payload: data,
});

export const fetchLatestNewsletters =
    (limit = 3, page = 1) =>
    async (dispatch) => {
        try {
            const response = await newsletterService.findLatest(limit, page);
            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(fetchLatestNewslettersSuccess(data));
        } catch (err) {
            throw { error: err.error };
        }
    };

export const fetchPrivateNewslettersUser = (userId) =>
    async (dispatch) => {
        try {
            const response = await newsletterService.findPrivateNewslettersUser(userId);
            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(fetchPrivateNewslettersUserSuccess(data));

            return data;
        } catch (err) {
            throw { error: err.error };
        }
};

export const fetchLatestFeedNewsletters = (userId) => async (dispatch) => {
    try {
        const response = await newsletterService.findLatestFeed(userId);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(
            fetchLatestFeedNewslettersSuccess(
                data.map((n) => ({
                    ...n,
                    articles: n.articles.slice(0, 5),
                }))
            )
        );

        return data;
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchLatestFeedSingleNewsletter =
    (userId, newsletterId, skip) => async (dispatch) => {
        try {
            const response =
                await newsletterService.findLatestFeedSingleNewsletter(
                    userId,
                    newsletterId,
                    skip
                );
            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(
                fetchLatestFeedSingleNewsletterSuccess({
                    ...data[0],
                    articles: data[0].articles.slice(0, 5),
                })
            );
            return data[0].articles;
        } catch (err) {
            console.log(err);
            throw { error: err.error };
        }
    };

export const fetchUnreadSpecificNewsletter =
    (userId, newsletterId, skip) => async (dispatch) => {
        try {
            const response =
                await newsletterService.findUnreadSpecificNewsletter(
                    userId,
                    newsletterId,
                    skip
                );
            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(
                fetchNewsletterByIdSuccess({
                    ...data,
                    articles: data.articles.slice(0, 5),
                })
            );
            return data.articles;
        } catch (err) {
            console.log(err);
            throw { error: err.error };
        }
    };

export const fetchPopularNewsletters = () => async (dispatch) => {
    try {
        const response = await newsletterService.findPopular();
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchPopularNewslettersSuccess(data));
    } catch (err) {
        throw { error: err.error };
    }
};

export const filterPopularNewsletters = (category) => async (dispatch) => {
    try {
        const response = await newsletterService.findPopularByCategory(
            category
        );

        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchPopularNewslettersSuccess(data));
    } catch (err) {
        throw { error: err.error };
    }
};

export const filterLatestNewslettersInitial =
    (category, limit = 3, page = 1) =>
    async (dispatch) => {
        try {
            const response = await newsletterService.findLatestByCategory(
                category,
                limit,
                page
            );

            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(filterLatestNewslettersInitialSuccess(data));
        } catch (err) {
            throw { message: err.error };
        }
    };

export const filterLatestNewsletters =
    (category, limit, page = 1) =>
    async (dispatch) => {
        try {
            const response = await newsletterService.findLatestByCategory(
                category,
                limit,
                page
            );

            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(fetchLatestNewslettersSuccess(data));
        } catch (err) {
            throw { message: err.error };
        }
    };

export const fetchLatestNewslettersInitial =
    (limit = 3, page = 1) =>
    async (dispatch) => {
        try {
            const response = await newsletterService.findLatest(limit, page);
            const data = await response.json();

            if (data.error) {
                throw { error: data.error };
            }

            dispatch(fetchLatestNewslettersInitialSuccess(data));
        } catch (err) {
            throw { error: err.error };
        }
    };

export const fetchDailyNewsletters = (email, page) => async (dispatch) => {
    try {
        const currentDate = dateProvider();

        const response = await newsletterService.findDaily(
            email,
            page,
            currentDate
        );

        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchDailyNewslettersSuccess(data));
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchLatestFeedIds = (userId) => async (dispatch) => {
    try {
        const response = await newsletterService.findLatestFeedIds(userId);

        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchDailyNewslettersIdsSuccess(data));
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchDailyNewslettersCount = async (userId) => {
    try {
        const currentDate = dateProvider();
        const response = await newsletterService.findDailyCount(
            userId,
            currentDate
        );
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }
        return data.count;
    } catch (err) {
        throw { error: err.error };
    }
};

export const createNewNewsletter = (newsletter, receiver) => async () => {
    try {
        const response = await newsletterService.createNewNewsletter(
            newsletter,
            receiver
        );
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchNewsletter = (id, skip) => async (dispatch) => {
    try {
        const response = await newsletterService.findNewsletterById(id, skip);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(
            fetchNewsletterByIdSuccess({
                ...data,
                articles: data.articles.slice(0, 5),
            })
        );

        return data.articles;
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchNewsletterInfo = (id) => async (dispatch) => {
    try {
        const response = await newsletterService.findNewsletterById(id, 0);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(
            fetchNewsletterInfoSuccess({
                ...data,
            })
        );

        return data.articles;
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchNumberExplorePages = async () => {
    try {
        const response = await newsletterService.findNumberExplorePages();

        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        return data.count;
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchNumberExploreCategoryPages = async (category) => {
    try {
        const response = await newsletterService.findNumberExploreCategoryPages(
            category
        );

        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        return data.count;
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchMoreNewsletterArticles = (id, page) => async (dispatch) => {
    try {
        const response = await newsletterService.findNewsletterById(id, page);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchMoreNewsletterArticlesSuccess(data.articles));
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchNewsletterArticlesCount = async (id) => {
    try {
        const response = await newsletterService.findNewsletterArticlesCount(
            id
        );
        const data = await response.json();
        if (data.error) {
            throw { error: data.error };
        }
        return data.articlesCount;
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchAllUnpublishedNewsletters = () => async (dispatch) => {
    try {
        const response =
            await newsletterService.findAllUnpublishedNewsletters();
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }
        dispatch(fetchAllUnpublishedNewslettersSuccess(data));
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchAllPublishedNewsletters = () => async (dispatch) => {
    try {
        const response = await newsletterService.findAllPublishedNewsletters();
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }
        dispatch(fetchAllPublishedNewslettersSuccess(data));
    } catch (err) {
        throw { error: err.error };
    }
};

export const editNewsletterInfo = (form, id) => async (dispatch) => {
    try {
        const response = await newsletterService.editNewsletterInfo(form, id);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(editNewsletterInfoSuccess({ ...form, id }));
    } catch (err) {
        throw { error: err.error };
    }
};

export const editNewslettersPrivateInfo = (form, id) => async (dispatch) => {
    try {
        const response = await newsletterService.editNewslettersPrivateInfo(
            form,
            id
        );
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(editNewslettersPrivateInfoSuccess({ ...form, id }));
    } catch (err) {
        throw { error: err.error };
    }
};

export const deleteNewsletter = (id, published) => async (dispatch) => {
    try {
        const response = await newsletterService.deleteNewsletter(id);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }
        published
            ? dispatch(deletePublishedNewsletterSuccess(id))
            : dispatch(deleteUnpublishedSuccess(id));
    } catch (err) {
        throw { error: err.error };
    }
};

export const unpublishNewsletter = (id) => async (dispatch) => {
    try {
        const response = await newsletterService.unpublishNewsletter(id);
        const data = await response.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(unpublishNewsletterSuccess(id));
    } catch (err) {
        throw { error: err.error };
    }
};

export const fetchNewslettersNotInFolder = (userId) => async (dispatch) => {
    try {
        console.log('user id: ', userId)
        const res = await newsletterService.fetchNewslettersNotInFolder(userId);
        const data = await res.json();

        if (data.error) {
            throw { error: data.error };
        }

        dispatch(fetchNewslettersNotInFolderSuccess(data));
    } catch (error) {
        console.log(error);
    }
};
export const removeNewsletterFromNewsletterNotInFolder =
    (userId, newsletterId) => async (dispatch) => {
        try {
            const res =
                await newsletterService.removeNewsletterFromNewsletterNotInFolder(
                    userId,
                    newsletterId
                );
            const data = await res.json();

            if (data.error) {
                throw { error: data.error };
            }
        } catch (error) {
            console.log(error);
        }
    };

export const changePositionsOfNewsletters =
    (startIndex, endIndex, newslettersNotInFolder, userId) =>
    async (dispatch) => {
        try {
            dispatch(changePositionsOfNewslettersSuccess(startIndex, endIndex));
            const res = await newsletterService.changePositionsOfNewsletters(
                startIndex,
                endIndex,
                newslettersNotInFolder,
                userId
            );
            const data = await res.json();
            if (data.error) {
                throw { error: data.error };
            }
        } catch (error) {
            console.log(error);
        }
    };
