import { Link, useLocation } from 'react-router-dom';
import './ExploreNav.scss';

const ExploreNav = () => {
    const { pathname } = useLocation();

    return (
        <div className='explore-nav-wrapper'>
            <ul>
                <li
                    className={
                        pathname.includes('/explore/collections') === false
                            ? 'selected'
                            : ''
                    }
                >
                    <Link to='/explore'>
                        <span>Newsletters</span>
                    </Link>
                </li>
                <li
                    className={
                        pathname.includes('/explore/collections')
                            ? 'selected'
                            : ''
                    }
                >
                    <Link to='/explore/collections' className='hidden'>
                        <span>Collections</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default ExploreNav;
