import { ReactComponent as ArrowIcon } from './assets/button-arrow.svg';
import './ProfileButton.scss';

const ProfileButton = ({ children, className, handleClick, isDisabled }) => {
    const initialClassName = ['profile-button'];
    className && initialClassName.push(`${className}`);

    return (
        <button
            className={initialClassName.join(' ')}
            onClick={handleClick}
            disabled={isDisabled}
        >
            <span>{children}</span>
            <span className="button-arrow">
                <ArrowIcon />
            </span>
        </button>
    );
};

export default ProfileButton;
