import ArticleLineInfo from '../ArticleLineInfo';
import { useEffect, useRef, useState } from 'react';
import { clearFavoritesArticles } from '../../../actions/articleActions';
import { fetchFavoriteArticles } from '../../../actions/articleActions';
import { getFavoriteArticles } from '../../../reducers/articleReducer';
import { getUserFavorites, getUserId } from '../../../reducers/userReducer';
import { textCutterRemoveHTML } from '../../../helpers/textCutter';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadMoreSinglePages from '../LoadMoreSinglePages';
import ArticlesLoader from '../ArticlesLoader';
import EmptyStateArticles from '../EmptyStateArticles';
import './ListingFavoriteArticles.scss';

const ListingFavoriteArticles = ({
    fetchFavoriteArticles,
    articles,
    userId,
    favorites,
    pages,
    clearFavoritesArticles,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hideButton, setHideButton] = useState(true);

    const location = useLocation()
        .pathname.split('/')
        .filter((a) => a.trim() !== '');
    const page =
        location[0] === 'today'
            ? 'today'
            : location[0] === 'favorites'
            ? 'favorites'
            : '';

    const currPage = useRef(0);
    const disableRender = useRef(false);
    const handleLoadMore = async () => {
        setIsLoadingMore(true);
        currPage.current += 1;
        if (pages - 1 === currPage.current) {
            setHideButton(true);
        }
        await fetchFavoriteArticles(userId, currPage.current);
        setIsLoadingMore(false);
    };

    useEffect(() => {
        try {
            if (
                userId &&
                !disableRender.current &&
                Object.keys(articles).length === 0
            ) {
                (async () => {
                    await fetchFavoriteArticles(userId, currPage.current);

                    setIsLoading(false);
                })();
                disableRender.current = true;
            }

            if (pages - 1 === currPage.current) {
                setHideButton(true);
            } else {
                if (favorites.length) {
                    setTimeout(() => setHideButton(false), 2000);
                }
            }
        } catch (error) {
            console.log(error);
        }

        return function cleanup() {
            clearFavoritesArticles();
        };
    }, [userId, fetchFavoriteArticles]);

    return (
        <>
            {isLoading ? (
                <ArticlesLoader />
            ) : articles && favorites.length ? (
                <>
                    {Object.keys(articles).map((n, i) => {
                        return (
                            <div
                                className='newsletters-favorites-wrapper'
                                key={i}
                            >
                                <p className='newsletter-favorites-name-wrapper'>
                                    <Link
                                        className='newsletter-favorites-name'
                                        to={`/newsletters/${articles[n][0]['newsletter']['_id']}`}
                                    >
                                        {n}
                                    </Link>
                                </p>
                                <div className='newsletter-articles-content'>
                                    {articles[n].map((ar) => (
                                        <ArticleLineInfo
                                            key={ar._id}
                                            date={ar.date}
                                            title={ar.title}
                                            page={page}
                                            content={textCutterRemoveHTML(
                                                ar.content,
                                                100
                                            )}
                                            id={ar._id}
                                            newsletter={
                                                articles[n][0]['newsletter'][
                                                    'name'
                                                ]
                                            }
                                            newsletterId={
                                                articles[n][0]['newsletter'][
                                                    '_id'
                                                ]
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </>
            ) : (
                <EmptyStateArticles textContent={'No favorite articles yet'} />
            )}
            {isLoadingMore && <ArticlesLoader />}
            <LoadMoreSinglePages handler={handleLoadMore} hidden={hideButton} />
        </>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    articles: getFavoriteArticles(state),
    favorites: getUserFavorites(state),
    pages: Math.ceil(getUserFavorites(state).length / 10),
});
const mapDispatchToProps = {
    fetchFavoriteArticles,
    clearFavoritesArticles,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingFavoriteArticles);
