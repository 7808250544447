import './ArticleFromPcForm.scss';
import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getUserId } from '../../../../reducers/userReducer';
import { uploadArticleFromUser } from '../../../../actions/articleActions';
import { notifyUser } from '../../../../helpers/notifications';
import { refreshMessage } from '../../../../helpers/reshreshMessageInProfilePage';
const ArticleFromPcForm = ({ userId, uploadArticleFromUser }) => {
    const [article, setArticle] = useState('');
    const [fileName, setFileName] = useState('.eml file');
    const [ready, setReady] = useState('no');
    const inputRef = useRef(null);
    const handleUploadEdition = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (!file.name.endsWith('.eml')) {
                setFileName('The file is not .eml!');
                refreshMessage(setFileName);
                setReady('no');
                return;
            }
            const reader = new FileReader();
            reader.readAsText(file, 'utf-8');
            setFileName(file.name);
            reader.onload = function () {
                setReady('yes');
                setArticle(reader.result);
            };
        }
    };

    const handleUploadToApi = async () => {
        try {
            inputRef.current.value = '';
            setFileName('...loading');

            await uploadArticleFromUser(userId, article);
            setFileName('Success!');
            refreshMessage(setFileName);
            setArticle('');
            setReady('no');
        } catch (error) {
            setFileName(error.error);
            refreshMessage(setFileName);
            setArticle('');
            setReady('no');
        }
    };

    return (
        <div className='article-from-pc-buttons-wrapper'>
            <h3 className='heading-for-uploading-editions'>
                Do you have favorite editions of newsletters but can't find them
                in the web?
            </h3>
            <p className='description-for-uploading-editions'>
                Now you can upload your editions directly from your computer!
                Just export them from your gmail and then upload them to the
                app!
            </p>
            <div className='input-and-button-wrapper-profile-settings-page'>
                <div className='input-and-button-inner-wrapper-profile-settings-page'>
                    <p className='file-status-settings'>Ready to upload: {ready}</p>
                    <div className='file-section-wrapper-article'>
                        <span>Choose .eml file</span>
                        <input
                            onChange={handleUploadEdition}
                            type='file'
                            className='article-upload'
                            ref={inputRef}
                        />
                    </div>
                    <button
                        className='upload-article'
                        onClick={handleUploadToApi}
                    >
                        Upload edition from pc
                    </button>
                </div>
                <div>
                    <p className='file-name'>
                        File: <span>{fileName}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    userId: getUserId(state),
});
const mapDispatchToProps = {
    uploadArticleFromUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(ArticleFromPcForm);
