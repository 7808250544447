import { articleApi } from './api';
import { userApi } from './api';
import requester from './requester';

export default {
    findFavoriteArticles: (userId, page) =>
        requester.get(userApi.findFavoriteArticles(userId, page)),
    findSearchContentMatch: (userId,query,page) =>
        requester.get(articleApi.findSearchContentMatch(userId,query,page)),
    findSearchContentMatchCount: (userId,query) =>
        requester.get(articleApi.findSearchContentMatchCount(userId,query)),
    findSingleArticle: (articleId) =>
        requester.get(articleApi.findSingleArticle(articleId)),
    createNewArticle: (article) =>
        requester.post(articleApi.createNewArticle(), article),
    uploadArticleFromUser: (userId, article) =>
        requester.post(articleApi.uploadArticleFromUser(userId), { article }),
};
