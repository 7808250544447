export const takeScrollCoords = () => {
    let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
    let height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
    let scrolled = Math.round((winScroll / height) * 100);

    if (isNaN(scrolled)) {
        return 0;
    }

    if (scrolled <= 10) {
        return scrolled;
    }
    if (scrolled >= 90) {
        return 100;
    }

    return scrolled + 10;
};

export const takeProgress = () => {
    let c = 2 * Math.PI * 16;
    let scrolled = takeScrollCoords();
    let progress = ((100 - scrolled) / 100) * c;

    return [progress, c];
};
