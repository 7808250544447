import { useHistory } from 'react-router-dom';
import FilterItem from './FilterItem';
import SortItems from './SortItems';
import './SearchFilters.scss';

const SearchFilters = ({ query }) => {
    const history = useHistory();

    const modifyQueryFilter = (filterQuery, toBeRemoved) => {
        if (filterQuery !== '') {
            history.push(`/search?query=${query}&filterBy=${filterQuery}`);
        } else {
            const newQuery = query.split(`&filterBy=${toBeRemoved}`);
            history.push(`/search?query=${newQuery.join('')}`);
        }
    }

    const modifyQuerySearchIn = (searchQueryIn, toBeRemoved) => {
        let newQuery = query.split(`&in=${toBeRemoved}`);

        if (searchQueryIn !== '') {
            if (!toBeRemoved && searchQueryIn === 'subscribed') {
                toBeRemoved = 'all';
                newQuery = query.split(`&in=${toBeRemoved}`);
            } else {
                toBeRemoved = 'subscribed';
                newQuery = query.split(`&in=${toBeRemoved}`);
            }
            
            history.push(`/search?query=${newQuery.join('')}&in=${searchQueryIn}`);
        } else {
            history.push(`/search?query=${newQuery.join('')}`);
        }
    }

    const modifyQuerySort = (sortQuery, toBeRemoved) => {
        const newQuery = query.split(`&sortBy=${toBeRemoved}`);

        if (sortQuery !== '') {
            history.push(`/search?query=${newQuery.join('')}&sortBy=${sortQuery}`);
        } else {
            history.push(`/search?query=${newQuery.join('')}`);
        }
    }


    return <div className="search-filters-wrapper">
        <SortItems modifyQuery={modifyQuerySort} />
        <div>
            <p className="filter-text">Search in:</p>
            <ul className="search-filters-list">
                <FilterItem modifyQuery={modifyQueryFilter}>Heading</FilterItem>
                <FilterItem modifyQuery={modifyQueryFilter}>Content</FilterItem>
            </ul>
            <p className="filter-text">Newsletters:</p>
            <ul className="search-filters-list">
                <FilterItem modifyQuery={modifyQuerySearchIn}>All</FilterItem>
                <FilterItem modifyQuery={modifyQuerySearchIn}>Subscribed</FilterItem>
            </ul>
        </div>
    </div>
}

export default SearchFilters;