import { ReactComponent as LoupeIcon } from './assets/loupe-icon.svg';
import { ReactComponent as GoBackIcon } from './assets/goBack-icon.svg';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getLastEntrance } from '../../../reducers/articleReducer';
import { getFromLS } from '../../../utils/storage';
import { connect } from 'react-redux';
import './Header.scss';

const Header = ({ backLinkUrl, backLink, lastEntrance, location }) => {
    const [searchVisible, setSearchVisible] = useState(false);
    const [visibleInput, setVisibleInput] = useState(false);
    const [inputFieldValue, setInputFieldValue] = useState('');
    const history = useHistory();

    const handleSearch = () => {
        if (inputFieldValue) {
            setInputFieldValue('');
            history.push(`/search?query=${inputFieldValue}&in=all`);
        }
    };
    const handleSearchKey = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleInputChange = (e) => {
        setInputFieldValue(e.target.value);
    };

    const handleVisibilitySearch = (e) => {
        if (searchVisible) {
            setSearchVisible(false);
            setVisibleInput(false);
        } else {
            setSearchVisible(true);
            setTimeout(() => {
                setVisibleInput(true);
            }, 500);
        }
    };

    return (
        <>
            <header className='main-header'>
                <div className='left-header-section-wrapper'></div>
                {!location.includes('articles') && getFromLS('role') ? (
                    <div className='right-header-section-wrapper'>
                        {searchVisible ? (
                            <div className='header-search-wrapper'>
                                <div
                                    className='header-search white-box-header'
                                    onClick={handleVisibilitySearch}
                                >
                                    <label htmlFor='search-input-field'>
                                        <LoupeIcon className='loupe-icon svg-icon' />
                                    </label>
                                </div>
                                {visibleInput && (
                                    <input
                                        className='header-search-input'
                                        autoComplete='off'
                                        id='search-input-field'
                                        onKeyPress={handleSearchKey}
                                        onChange={handleInputChange}
                                        value={inputFieldValue}
                                    ></input>
                                )}
                            </div>
                        ) : (
                            <div className='header-search-wrapper-small'>
                                <div
                                    className='header-search white-box-header'
                                    onClick={handleVisibilitySearch}
                                >
                                    <label htmlFor='search-input-field'>
                                        <LoupeIcon className='loupe-icon svg-icon' />
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}
            </header>
        </>
    );
};

const mapStateToProps = (state) => ({
    lastEntrance: getLastEntrance(state),
});

export default connect(mapStateToProps, null)(Header);
