import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FilterItem = ({ children, modifyQuery }) => {
    const query = useLocation().search;

    const [selected, setSelected] = useState(false);

    const handleSelectAdditionalFilters = (queryToAdd) => {
        selected
            ? setSelected(false)
            : setSelected(true)

        if (!selected) {
            modifyQuery(queryToAdd);
        } else {
            modifyQuery('', queryToAdd);
        }

    }

    useEffect(() => {

        if (query.includes(`filterBy=${children.toLowerCase()}`)) {
            setSelected(true);
        } else if (query.includes(`in=${children.toLowerCase()}`)) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }, [children, query])
    return <>
        <li className={`search-filters-list-item ${selected && 'selected'}`}
            onClick={e => handleSelectAdditionalFilters(e.currentTarget.textContent.toLowerCase())}>{children}</li>
    </>
}

export default FilterItem;