import amplitude from 'amplitude-js';
import { AMPLITUDE_API } from '../config/config';

export const initAmplitude = () => {
    amplitude
        .getInstance()
        .init(
            AMPLITUDE_API,
            null,
            {
                includeUtm: true,
                includeReferrer: true,
                sessionTimeout: 1000 * 60 * 30,
            },
        );
};
export const setAmplitudeUserDevice = (installationToken) => {
    amplitude.getInstance().setDeviceId(installationToken);
};
export const setAmplitudeUserId = (userId) => {
    amplitude.getInstance().setUserId(userId);
};
export const setAmplitudeUserProperties = (properties) => {
    amplitude.getInstance().setUserProperties(properties);
};
export const sendAmplitudeData = (eventType, eventProperties) => {
    amplitude.getInstance().logEvent(eventType, eventProperties);
};
export const sendAmplitudeIdentifyData = (key,value) => {
    let identify = new amplitude.Identify().set(key,value);
    amplitude.getInstance().identify(identify);
};
