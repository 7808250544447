import OpenSideMenu from '../SideMenu/OpenSideMenu';
import { ReactComponent as LogoOpenSideMenu } from './assets/Logo-collapsed-menu-new.svg';
import { connect } from 'react-redux';
import { openSideMenu, closeSideMenu } from '../../../actions/sideMenuActions';
import { fetchUsersFolders } from '../../../actions/folderActions';
import { getUserFolders, getUserId } from '../../../reducers/userReducer';
import React from 'react';
import { Link } from 'react-router-dom';
import './SideMenuMobile.scss';
import ProfileMenu from '../ProfileMenu';

const SideMenu = ({
    opened,
    openSideMenu,
    closeSideMenu,
    folders,
    fetchUsersFolders,
    userId,
    setNotification,
    menuSettingsClick,
    settingsMenu,
    handleToggleTheme,
    light,
    setVisibilitySettings,
}) => {
    const handleSideMenu = () => {
        opened ? closeSideMenu() : openSideMenu();
        if (folders.length === 0) {
            fetchUsersFolders(userId);
        }
    };
    const handleCollapseMenu = (e) => {
        e.stopPropagation();
    };

    const handleCloseProfileMenu = (e) => {
        settingsMenu && setVisibilitySettings(false);
    };
    return (
        <div
            className={
                opened
                    ? 'menu-wrapper-mobile menu-wrapper-opened-mobile'
                    : 'menu-wrapper-mobile'
            }
            onClick={handleCollapseMenu}
        >
            <aside
                className={
                    opened
                        ? 'side-menu-mobile side-menu-opened-mobile'
                        : 'side-menu-mobile'
                }
            >
                <div
                    className='side-menu-content-mobile'
                    onClick={handleCloseProfileMenu}
                >
                    <OpenSideMenu
                        handleSideMenu={handleSideMenu}
                        setNotification={setNotification}
                        handleToggleTheme={handleToggleTheme}
                        light={light}
                    />
                </div>

            </aside>
        </div>
    );
};

const mapStateToProps = (state) => ({
    opened: state.sideMenu.opened,
    folders: getUserFolders(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    openSideMenu,
    closeSideMenu,
    fetchUsersFolders,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
