import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchPrivateNewslettersUser } from '../../../../actions/newsletterActions';
import {
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
} from '../../../../actions/userActions';
import { removeNewsletterFromFolderSuccess } from '../../../../actions/folderActions';
import { ReactComponent as ArrowIcon } from '../assets/arrowIcon.svg';
import {
    getUserId,
    getUserNewsletters,
} from '../../../../reducers/userReducer';
import { getPersonal } from '../../../../reducers/newsletterReducer';
import { sendAmplitudeData } from '../../../../utils/amplitude';
import moment from 'moment';
import './PersonalNewsletters.scss';

const PersonalNewsletters = ({
    userId,
    fetchPrivateNewslettersUser,
    privateNewsletters,
    newsletters,
    removeNewsletterFromFolderSuccess,
    subscribeForNewsletter,
    unsubscribeFromNewsletter,
}) => {
    const [newslettersIds, setNewslettersIds] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchPrivateNewslettersUser(userId);
        };

        if (newsletters.length) {
            setNewslettersIds(newsletters.map((n) => n._id));
        }

        userId && fetchData();
    }, [
        userId,
        fetchPrivateNewslettersUser,
        getPersonal,
        getUserId,
        getUserNewsletters,
        newsletters,
    ]);

    const handleSubscribe = async (id, status) => {
        try {
            if (status) {
                await unsubscribeFromNewsletter(userId, id);
                await removeNewsletterFromFolderSuccess(id);
            } else {
                await subscribeForNewsletter(userId, id);
            }
        } catch (error) {
            console.log(error.code || error.error);
        }
    };

    return (
        <>
            {privateNewsletters && privateNewsletters.length ? (
                <h2 className='admin-personal-heading'>Personal Newsletters</h2>
            ) : null}
            <div className='admin-personal-newsletters-wrapper'>
                {privateNewsletters.map((x, i) => (
                    <div
                        key={`${x._id}${i}`}
                        className='admin-personal-newsletters'
                    >
                        <div className='admin-personal-logo-wrapper'>
                            <img
                                src={x.logo}
                                alt={x.name}
                                className='admin-personal-logo'
                            />
                        </div>

                        <div className='admin-personal-name'>
                            <p className='admin-personal-name-publisher'>
                                {x.name}
                            </p>
                            <p className='admin-personal-name-date'>
                                {newslettersIds.includes(x._id) ? (
                                    <span>
                                        Subbed on{' '}
                                        {moment(x.subscribedDate || Date.now()).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </span>
                                ) : (
                                    <span>
                                        Unsubbed on{' '}
                                        {moment(x.unsubscribedDate || Date.now()).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>

                        <div className='admin-personal-button-wrapper'>
                            {userId && (
                                <p
                                    onClick={() =>
                                        handleSubscribe(
                                            x._id,
                                            newslettersIds.includes(x._id)
                                        )
                                    }
                                    className='admin-personal-button'
                                >
                                    {newslettersIds.includes(x._id) ? (
                                        <span>Unsubscribe</span>
                                    ) : (
                                        <span>Subscribe</span>
                                    )}
                                </p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    newsletters: getUserNewsletters(state),
    privateNewsletters: getPersonal(state),
});
const mapDispatchToProps = {
    fetchPrivateNewslettersUser,
    unsubscribeFromNewsletter,
    removeNewsletterFromFolderSuccess,
    subscribeForNewsletter,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalNewsletters);
