const ReadIcon = ({ dashOffset, stroke }) => {
    return (
        <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={stroke ? 'read-icon stroked' : 'read-icon'}
        >
            <circle
                cx='16'
                cy='16'
                r='16'
                fill='#F9F9F9'
                strokeWidth='2'
                strokeDasharray='100.531'
                strokeDashoffset={dashOffset}
                transform='rotate(-90 16 16)'
            />
            <path
                d='M10 15.5L14.5 20L22.5 12'
                stroke='#1C1C1C'
                strokeWidth='2'
            />
        </svg>
    );
};

export default ReadIcon;
