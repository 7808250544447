import HeaderPages from '../HederPages';
import UserFolders from '../UserFolders';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import { notifyUser } from '../../../../helpers/notifications';
import { connect } from 'react-redux';
import {
    addNewsletterToUsersFolder,
    returnNewsletterToMainFolder,
    addNewsletterToAnotherFolder,
    changePositionsInFolder,
} from '../../../../actions/folderActions';
import {
    changePositionsOfNewsletters,
    removeNewsletterFromNewsletterNotInFolder,
} from '../../../../actions/newsletterActions';
import {
    getUserFolders,
    getUserId,
    getUserNewsletters,
    getUserNewslettersNotInFolder,
} from '../../../../reducers/userReducer';
import { ReactComponent as ProfileIcon } from '../assets/ProfileIcon.svg';

const OpenSideMenu = ({
    handleSideMenu,
    setNotification,
    menuSettingsClick,
    addNewsletterToUsersFolder,
    userId,
    newsletters,
    folders,
    changePositionsOfNewsletters,
    newslettersNotInFolder,
    removeNewsletterFromNewsletterNotInFolder,
    handleToggleTheme,
    light,
    returnNewsletterToMainFolder,
    addNewsletterToAnotherFolder,
    changePositionsInFolder
}) => {
    const [options, setOptions] = useState(false);
    const handleCloseOptions = () => {
        options && setOptions(false);
    };
    const addNewsletterToFolder = async (info) => {
        console.log(info);
        window.onscroll = function () { };
        if (!info.destination) {
            return;
        }
        if (
            info.destination.droppableId !== '1' &&
            info.source.droppableId === '1'
        ) {
            const newsletter = newsletters.find(
                (x) => x._id === info.draggableId
            );
            const folder = folders.find(
                (x) => x._id === info.destination.droppableId
            );

            try {
                await addNewsletterToUsersFolder(
                    userId,
                    {
                        _id: newsletter._id,
                        name: newsletter.name,
                        logo: newsletter.logo,
                        articles: newsletter.articles,
                        isPublic: newsletter.isPublic,
                    },
                    folder.name,
                    folder._id,
                );
                await removeNewsletterFromNewsletterNotInFolder(
                    userId,
                    newsletter._id
                );
                notifyUser(
                    'success',
                    'Added newsletter to folder!',
                    setNotification
                );
            } catch (error) {
                notifyUser('error', error.error.error, setNotification);
            }
        } else if (
            info.destination.droppableId === '1' &&
            info.source.droppableId === '1'
        ) {
            const { source, destination } = info;
            if (destination.index === source.index) {
                return;
            }

            await changePositionsOfNewsletters(
                source.index,
                destination.index,
                newslettersNotInFolder,
                userId
            );
        } else if (
            info.destination.droppableId === '1' &&
            info.source.droppableId !== '1'
        ) {
            const { source, destination, draggableId } = info;

            await returnNewsletterToMainFolder(
                source.droppableId,
                draggableId,
                userId,
                destination.index
            );
        } else if (
            info.destination.droppableId !== '1' &&
            info.source.droppableId !== '1' &&
            info.destination.droppableId !== info.source.droppableId
        ) {
            const { source, destination, draggableId } = info;

            await addNewsletterToAnotherFolder(
                userId,
                source.droppableId,
                draggableId,
                destination.droppableId
            );
        } else if (
            info.destination.droppableId !== '1' &&
            info.source.droppableId !== '1' &&
            info.destination.droppableId === info.source.droppableId
        ) {
            const { source, destination, draggableId } = info;
            await changePositionsInFolder(
                userId,
                destination.droppableId,
                draggableId,
                destination.index,
                source.index
            );
        }
    };

    const stopScroll = () => {
        const currPositionX = window.scrollW;
        const currPositionY = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(currPositionX, currPositionY - 5);
        };
    };
    return (
        <>
            <div onClick={handleCloseOptions}>
                <HeaderPages />
                <DragDropContext
                    onDragStart={stopScroll}
                    onDragEnd={(result) => addNewsletterToFolder(result)}
                >
                    <UserFolders
                        options={options}
                        setOptions={setOptions}
                        setNotification={setNotification}
                        handleToggleTheme={handleToggleTheme}
                        light={light}
                    />
                </DragDropContext>
            </div>
            <p
                className='my-profile-opened-side-menu'
                onClick={menuSettingsClick}
            >
                <ProfileIcon className='profile-icon svg-icon' />
            </p>
        </>
    );
};

const mapStateToProps = (state) => ({
    userId: getUserId(state),
    newsletters: getUserNewsletters(state),
    newslettersNotInFolder: getUserNewslettersNotInFolder(state),
    folders: getUserFolders(state),
});
const mapDispatchToProps = {
    addNewsletterToUsersFolder,
    changePositionsOfNewsletters,
    removeNewsletterFromNewsletterNotInFolder,
    returnNewsletterToMainFolder,
    addNewsletterToAnotherFolder,
    changePositionsInFolder,
};
export default connect(mapStateToProps, mapDispatchToProps)(OpenSideMenu);
