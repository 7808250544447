import userService from '../services/userService';
import { emailRegex, passwordRegex, usernameRegex } from './regex';

export const validateLogin = (
    email,
    handlerEmail,
    password,
    handlerPassword
) => {
    let error = false;

    if (!emailRegex.test(email)) {
        handlerEmail('Wrong email');
        error = true;
    }

    if (!passwordRegex.test(password)) {
        handlerPassword('Minimum 6 symbols with digit and letter');
        error = true;
    }

    if (error) {
        throw { error: 'The user does not exist!' };
    }
};

export const validateEmail = (email) => {
    if (email.trim() === '') {
        throw { error: 'The email field can not be empty' };
    }

    if (!emailRegex.test(email)) {
        throw { error: 'The email is not the right format' };
    }
};

export const validatePassword = (password) => {
    if (!passwordRegex.test(password)) {
        throw { error: 'Minimum 6 symbols with digit and letter' };
    }
};

export const comparePasswords = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        throw { error: 'The passwords do not match' };
    }
};

export const findIfUsernameAlreadyExists = async (username) => {
    const response = await userService.findUserByUsername(username);
    const data = await response.json();

    if (data.error) {
        throw { error: data.error };
    }

    return data.email;
};
export const validateUsername = (username) => {
    if (!usernameRegex.test(username)) {
        throw { error: 'Minimum 3 symbols, only digits and letters' };
    }
};

export const checkIfEmailExists = async (email) => {
    const response = await userService.findEmail(email);

    if (response.ok) {
        throw { error: 'User already exists!' };
    }
};
