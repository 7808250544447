import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getPopularNewsletters } from '../../../../reducers/newsletterReducer';
import {
    filterPopularNewsletters,
    clearPopularNewsletters,
} from '../../../../actions/newsletterActions';
import { prettifySubPath } from '../../../../helpers/textModifier';

import ExploreCard from '../../../shared/ExploreCard';
import '../Cards.scss';
import { getAllCategories } from '../../../../reducers/categoryReducer';

const SortedPopularCards = ({
    filterPopularNewsletters,
    clearPopularNewsletters,
    popularNewsletters,
    category,
    categories,
}) => {
    let categoryPretty = prettifySubPath(category);

    useEffect(() => {
        if (categories.length) {
            categoryPretty = categories.filter(
                (c) =>
                    c.name.toLocaleLowerCase() ===
                    categoryPretty.toLocaleLowerCase()
            )[0].name;

            filterPopularNewsletters(categoryPretty);
        }

        return () => {
            clearPopularNewsletters();
        };
    }, [filterPopularNewsletters, categories]);

    return (
        <div className='onboarding-cards-wrapper'>
            <h2>
                Popular{' '}
                <span className='category-info'>in {categoryPretty}</span>
            </h2>
            <div className='onboarding-cards'>
                {popularNewsletters.map((x) => (
                    <ExploreCard
                        newsletter={x}
                        description={x.description}
                        articles={x.articles}
                        id={x._id}
                        key={x._id}
                    />
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    popularNewsletters: getPopularNewsletters(state),
    categories: getAllCategories(state),
});

const mapDispatchToProps = {
    filterPopularNewsletters,
    clearPopularNewsletters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortedPopularCards);
