import { ReactComponent as Arrow } from './assets/sign-up-in-arrow.svg';
import './Button.scss';

const Button = ({ children, handleClick, className, disabled, arrow }) => {
    return (
        <button
            className={`${className ? className : 'centered-button-orange'
                } ${arrow && 'flex-button'
                } centered-button`}
            onClick={handleClick}
            disabled={disabled ? true : false}
        >
            <span>{children}</span>
            { arrow && <Arrow />}
        </button>
    );
};

export default Button;
