/* eslint-disable import/no-anonymous-default-export */
import user from '../reducers/userReducer';
import { newsletterApi } from './api';
import { userApi } from './api';
import requester from './requester';

export default {
    findDaily: (email, page, date) =>
        requester.get(userApi.findDaily(email, page, date)),
    findLatestFeed: (userId) => requester.get(userApi.findLatestFeed(userId)),
    findLatestFeedSingleNewsletter: (userId, newsletterId, skip) =>
        requester.get(
            userApi.findLatestFeedSingleNewsletter(userId, newsletterId, skip)
        ),
    findUnreadSpecificNewsletter: (userId, newsletterId, skip) =>
        requester.get(
            userApi.findUnreadSpecificNewsletter(userId, newsletterId, skip)
        ),
    findDailyArticlesIds: (email, date) =>
        requester.get(userApi.findDailyArticlesIds(email, date)),
    findLatestFeedIds: (userId) =>
        requester.get(userApi.findLatestFeedIds(userId)),
    findDailyCount: (userId, date) =>
        requester.get(userApi.findDailyCount(userId, date)),
    findPopular: () => requester.get(newsletterApi.findPopular()),
    findPrivateNewslettersUser: (userId) => requester.get(newsletterApi.findPrivateNewslettersUser(userId)),
    findPopularByCategory: (category) =>
        requester.get(newsletterApi.findPopularByCategory(category)),
    findLatest: (limit, page) =>
        requester.get(newsletterApi.findLatest(limit, page)),
    findLatestByCategory: (category, limit, page) =>
        requester.get(
            newsletterApi.findLatestByCategory(category, limit, page)
        ),
    createNewNewsletter: (newsletter, receiver) =>
        requester.post(newsletterApi.createNewNewsletter(), {
            ...newsletter,
            receiver,
        }),
    findNewsletterById: (id, skip) =>
        requester.get(newsletterApi.findNewsletterById(id, skip)),
    findNumberExplorePages: () =>
        requester.get(newsletterApi.findNumberExplorePages()),
    findNumberExploreCategoryPages: (category) =>
        requester.get(newsletterApi.findNumberExploreCategoryPages(category)),
    findNewsletterArticlesCount: (id) =>
        requester.get(newsletterApi.findNewsletterArticlesCount(id)),
    findAllUnpublishedNewsletters: () =>
        requester.get(newsletterApi.findAllUnpublishedNewsletters()),
    findAllPublishedNewsletters: () =>
        requester.get(newsletterApi.findAllPublishedNewsletters()),
    editNewsletterInfo: (form, id) =>
        requester.put(newsletterApi.editNewsletterInfo(id), { ...form }),
    editNewslettersPrivateInfo: (form, id) =>
        requester.put(newsletterApi.editNewslettersPrivateInfo(id), { ...form }),
    deleteNewsletter: (id) =>
        requester.delete(newsletterApi.deleteNewsletter(id)),
    unpublishNewsletter: (id) =>
        requester.put(newsletterApi.editNewsletterInfo(id), {
            isPublic: false,
        }),
    findNewslettersByCategory: (categoryId) =>
        requester.get(newsletterApi.findNewslettersByCategory(categoryId)),
    fetchNewslettersNotInFolder: (userId) =>
        requester.get(userApi.fetchNewslettersNotInFolder(userId)),
    changePositionsOfNewsletters: (
        startIndex,
        endIndex,
        newslettersNotInFolder,
        userId
    ) =>
        requester.put(userApi.changePositionsOfNewsletters(userId), {
            startIndex,
            endIndex,
            newslettersNotInFolder,
        }),
    removeNewsletterFromNewsletterNotInFolder: (userId, newsletterId) =>
        requester.put(
            userApi.removeNewsletterFromNewsletterNotInFolder(
                userId,
                newsletterId
            )
        ),
};
