import { useState } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import {
    deleteCategory,
    editCategory,
    fetchNewslettersByCategory,
} from '../../../../../actions/categoryActions';
import { getNewslettersByCategory } from '../../../../../reducers/categoryReducer';
import { notifyUser } from '../../../../../helpers/notifications';

import './SingleCategory.scss';

const SingleCategory = ({
    name,
    categoryId,
    deleteCategory,
    editCategory,
    setNotification,
    index,
    fetchNewslettersByCategory,
    newsletters,
}) => {
    const [editFieldIsOpened, setEditFieldIsOpened] = useState(false);
    const [expandCategory, setExpandCategory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState(name);

    const onEditCategoryClickHandler = () => {
        setEditFieldIsOpened((prevState) => !prevState);

        if (!editFieldIsOpened) {
            setInputValue(name);
        }
    };

    const onDeleteCategoryClickHandler = async () => {
        try {
            await deleteCategory(categoryId);
            notifyUser(
                'success',
                'Category deleted successfully',
                setNotification
            );
        } catch (err) {
            notifyUser('error', err.error, setNotification);
        }
    };

    const onConfirmEditClickHandler = async () => {
        try {
            if (inputValue !== name) {
                await editCategory(categoryId, { name: inputValue });
                notifyUser(
                    'success',
                    'Category edited successfully',
                    setNotification
                );
            }
            setEditFieldIsOpened(false);
        } catch (err) {
            notifyUser('error', err.error, setNotification);
        }
    };

    const onShowClickHandler = async () => {
        try {
            setExpandCategory((prevState) => !prevState);

            if (!expandCategory) {
                setIsLoading(true);
                await fetchNewslettersByCategory(categoryId);
                setIsLoading(false);
            }
        } catch (err) {
            notifyUser('error', err.error, setNotification);
        }
    };

    return (
        <Draggable draggableId={categoryId} index={index}>
            {(provided, snapshot) => (
                <div
                    className={`single-category${
                        snapshot.isDragging ? ' draggable' : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className='single-category-main-info'>
                        {editFieldIsOpened ? (
                            <div>
                                <input
                                    value={inputValue}
                                    onChange={(e) =>
                                        setInputValue(e.target.value)
                                    }
                                />
                                <button onClick={onConfirmEditClickHandler}>
                                    Confirm
                                </button>
                            </div>
                        ) : (
                            <span>{name}</span>
                        )}
                        <div className='single-category-actions'>
                            <button onClick={onEditCategoryClickHandler}>
                                {editFieldIsOpened ? 'Cancel' : 'Edit'}
                            </button>
                            <button onClick={onDeleteCategoryClickHandler}>
                                Delete
                            </button>
                            <button onClick={onShowClickHandler}>
                                {expandCategory ? 'Hide' : 'Expand'}
                            </button>
                        </div>
                    </div>
                    <div className='category-newsletters-wrapper'>
                        {expandCategory &&
                            (!isLoading && newsletters.length === 0 ? (
                                <p>
                                    This category doesn't have any newsletters
                                </p>
                            ) : (
                                <ul>
                                    {newsletters.map((x) => (
                                        <Link
                                            to={`/newsletters/${x._id}`}
                                            key={x._id}
                                        >
                                            <li>
                                                <span>{x.name}</span>
                                            </li>
                                        </Link>
                                    ))}
                                </ul>
                            ))}
                    </div>
                </div>
            )}
        </Draggable>
    );
};

const mapStateToProps = (state, ownProps) => ({
    newsletters: getNewslettersByCategory(state, ownProps.categoryId),
});

const mapDispatchToProps = {
    deleteCategory,
    editCategory,
    fetchNewslettersByCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleCategory);
