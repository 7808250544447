import { getUsername, getUserRole } from '../../../reducers/userReducer';
import { closeSideMenu, openFeedbackModal } from '../../../actions/sideMenuActions';
import { logout } from '../../../actions/userActions';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFromLS } from '../../../utils/storage';
import { ReactComponent as CopyClipBoard } from './assets/copy-icon.svg';
import './ProfileMenu.scss';
import { useState } from 'react';

const ProfileMenu = ({
    className,
    username,
    logout,
    userRole,
    handleToggleTheme,
    light,
    closeSideMenu,
    openFeedbackModal
}) => {
    const [isCopied, setIsCopied] = useState(false);
    const history = useHistory();

    const handleLogout = async () => {
        try {
            handleCloseMenu();
            await logout();
            history.push('/');
        } catch (error) {
            console.log(error.error || error.message);
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const handleCloseMenu = () => {
        const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );

        if (vw <= 839) {
            closeSideMenu();
        }
    };

    const copyToClipBoard = () => {
        navigator.clipboard.writeText(username);
        setIsCopied(true);
    };

    return (
        <>
            {getFromLS('role') ? (
                <aside
                    className={`${className} side-menu-settings`}
                    onClick={handleClick}
                >
                    <div className='settings-menu-wrapper'>
                        <div className='settings-menu'>
                            <div className='profile-settings-menu'>
                                <Link
                                    className='profile-settings-menu-profile'
                                    to='/profile'
                                    onClick={handleCloseMenu}
                                >
                                    Profile & Settings
                                </Link>
                            </div>
                            <div className='options-settings-menu'>
                                <span className='options-settings-menu-theme'>
                                    Theme
                                </span>
                                <div className='theme-settings-icon-switch-wrapper'>
                                    <span>{light ? 'Light' : 'Dark'}</span>
                                    <div className='theme-settings-menu-text-right'>
                                        <div
                                            className={`${light
                                                ? 'theme-settings-light'
                                                : 'theme-settings-dark'
                                                } theme-settings-icon`}
                                            onClick={handleToggleTheme}
                                        >
                                            <div className='theme-settings-icon-switch'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {userRole === 'ADMIN' ? (
                                <div className='feedback-menu-bottom'>
                                    <Link to='/admin' onClick={handleCloseMenu}>
                                        Admin panel
                                    </Link>
                                </div>
                            ) : (
                                <div
                                    className='feedback-menu-bottom'
                                    onClick={e => openFeedbackModal()}
                                >
                                    <Link to='#'>Give us feedback</Link>
                                </div>
                            )}
                            <div className='logout-settings-menu'>
                                <p onClick={handleLogout}>Logout</p>
                            </div>
                        </div>
                        <div className='settings-menu-bottom'>
                            <p>{username}</p>
                            <CopyClipBoard
                                onClick={copyToClipBoard}
                            ></CopyClipBoard>
                        </div>
                    </div>
                </aside>
            ) : null}
        </>
    );
};

const mapStateToProps = (state) => ({
    username: getUsername(state),
    userRole: getUserRole(state),
});
const mapDispatchToProps = {
    logout,
    closeSideMenu,
    openFeedbackModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
