import './ForgottenPasswordForm.scss';
import InputField from '../../../shared/InputField';
import MainHeading from '../../../shared/MainHeading';
import Button from '../../../shared/Button';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { findIfUsernameAlreadyExists } from '../../../../helpers/validators';
import { getUserEmail } from '../../../../reducers/userReducer';
import { auth } from '../../../../utils/firebase';

const ForgottenPasswordInput = ({ email }) => {
    const [values, setValues] = useForm({ letterbox: '' });
    const [letterboxError, setLetterboxError] = useState('');
    const [emailReset, setEmailReset] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLetterboxError('');

        try {

            if (!values.letterbox) {
                throw {error: 'Invalid email!'}
            }

            setLoading(true);
            const emailToSend = await findIfUsernameAlreadyExists(
                values.letterbox
            );
            

            setEmailReset(emailToSend);
            await auth.sendPasswordResetEmail(emailToSend);

            history.push('/');
        } catch (error) {
            
            setLoading(false);
            setLetterboxError(error.code || error.error);
        }
    };

    return (
        <div className='form-forgotten-wrapper'>
            <form className='form-forgotten' onSubmit={handleSubmit}>
                <div>
                    <MainHeading>Forgot something?</MainHeading>
                    <p className='last-paragraph'>
                        Enter your myletterbox.io account Email and we will send a
                        link
                        <span> to setup a new password</span>
                    </p>
                    <InputField
                        placeholder='Email address'
                        type='email'
                        defaultPlaceholder='You email address...'
                        value={values.letterbox}
                        handler={setValues}
                        name='letterbox'
                        error={letterboxError}
                        id='forgotten-password'
                    />
                    {loading ? (
                        <Button disabled>LOADING...</Button>
                    ) : (
                        <Button>GET IN</Button>
                    )}
                    <span className='create-your-account-wrapper'>
                        <Link to='/'>Create your account!</Link>
                    </span>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    email: getUserEmail(state),
});

export default connect(mapStateToProps)(ForgottenPasswordInput);
