/* eslint-disable import/no-anonymous-default-export */
import { userApi } from './api';
import { auth } from '../utils/firebase';
import requester from '../services/requester';

export default {
    register: (email, password) =>
        requester.post(userApi.register(), { email, password }),
    registerWithSocial: (userEmail) =>
        requester.post(userApi.registerWithSocial(), { email: userEmail }),
    updatePassword: (userId, currentPassword, newPassword) =>
        requester.put(userApi.updatePassword(), {
            userId,
            currentPassword,
            newPassword,
        }),
    verifyPasswordResetCode: (actionCode) =>
        auth.verifyPasswordResetCode(actionCode),
    confirmPasswordReset: (email, newPassword) =>
        requester.put(userApi.resetPassword(), {
            email,
            password: newPassword,
        }),
    findEmail: (email) => requester.get(userApi.findUserByEmail(email)),
    findUserPlan: (userId) => requester.get(userApi.findUserPlan(userId)),
    preSubscribe: (email) => requester.post(userApi.preSubscribe(), { email }),
    updatePhoto: (userId, photoURL) =>
        requester.put(userApi.updatePhoto(userId), {
            photoURL,
        }),
    updateName: (userId, name) =>
        requester.put(userApi.updateName(userId), {
            name,
        }),
    changeStatus: (userId, isBlocked) =>
        requester.put(userApi.updatePhoto(userId), {
            isBlocked,
        }),
    createUsernameOnExistingUser: (userId, username) =>
        requester.put(userApi.createUsername(userId), { username }),
    findUserByUsername: (username) =>
        requester.get(userApi.findUserByUsername(username)),
    subscribeForNewsletter: (userId, newsletterId) =>
        requester.post(userApi.subscribeForNewsletter(userId), {
            newsletterId,
        }),
    addArticleToFavorites: (userId, articleId) =>
        requester.post(userApi.addArticleToFavorites(userId), {
            articleId,
        }),
    addArticleToRead: (userId, articleId) =>
        requester.post(userApi.addArticleToRead(userId), {
            articleId,
        }),
    removeArticleFromFavorites: (userId, articleId) =>
        requester.delete(userApi.removeArticleFromFavorites(userId, articleId)),
    removeArticleFromRead: (userId, articleId) =>
        requester.delete(userApi.removeArticleFromRead(userId, articleId)),
    findUserFavorites: (userId) =>
        requester.get(userApi.findUserFavorites(userId)),
    findUserRead: (userId) => requester.get(userApi.findUserRead(userId)),
    findUserNewsletters: (userId) =>
        requester.get(userApi.findUserNewsletters(userId)),
    unsubscribeFromNewsletter: (userId, newsletterId) =>
        requester.delete(
            userApi.unsubscribeFromNewsletter(userId, newsletterId)
        ),
    findUser: (email) => requester.get(userApi.findUserByEmail(email)),
    findNameOfUser: (userId) => requester.get(userApi.findNameOfUser(userId)),
    findUserInitialInfo: (userId) =>
        requester.get(userApi.findUserInitialInfo(userId)),
    findAllUsersDetails: () => requester.get(userApi.findAllUsersDetails()),
    checkSmtpStatus: () => requester.get(userApi.checkSmtpStatus()),
    subscribeToAllNewslettersFromFolder: (userId, newslettersIds) =>
        requester.post(userApi.subscribeToAllNewslettersFromFolder(userId), {
            newslettersIds,
        }),
    stripeFetchCheckoutSession: (userId) =>
        requester.post(userApi.stripeFetchCheckoutSession(userId)),
    stripeManagePortalUrl: (userId) =>
        requester.post(userApi.stripeManagePortalUrl(userId)),
    stripeActionNeeded: (userId) =>
        requester.post(userApi.stripeActionNeeded(userId)),
    findUnseen: (userId) => requester.get(userApi.findUnseen(userId)),
    markAllArticlesFromNewsletterAsReadAndSeen: (userId, newsletterId) =>
        requester.put(
            userApi.markAllArticlesFromNewsletterAsReadAndSeen(
                userId,
                newsletterId
            )
        ),
    removeFromUnseen: (userId, newsletterId, articleId) =>
        requester.put(userApi.removeFromUnseen(userId), {
            seen: true,
            newsletterId,
            articleId,
        }),
    changeUserPlan: (userId) => requester.post(userApi.changeUserPlan(userId))
};
