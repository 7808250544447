import { useState } from 'react';
import { connect } from 'react-redux';

import { getUserEmail } from '../../../reducers/userReducer';
import { sendFeedback, closeFeedbackModal } from '../../../actions/sideMenuActions';

import CircleLoader from '../CircleLoader/CircleLoader';
import { ReactComponent as ArrowIcon } from './assets/button-arrow.svg';

import './FeedbackModal.scss';

const FeedbackModal = ({ userEmail, isOpen, sendFeedback, closeFeedbackModal }) => {
  const [message, setMessage] = useState('');
  const [hasSent, setHasSent] = useState(false);
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);

  const handleSubmitClicked = async (e) => {
    if (hasSent) return;

    e.preventDefault();
    e.stopPropagation();

    setIsSendingFeedback(true);

    await sendFeedback(userEmail, message);

    setHasSent(true);
    setIsSendingFeedback(false);
  }

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    closeFeedbackModal();
  }

  return (
    <div className={`${isOpen ? 'open' : ''} feedback-modal__container`}>
      <div className='feedback-modal__content'>
        <span
          className='feedback-modal__close-button'
          onClick={handleClose}
        >
          Close
        </span>

        <div className='feedback-modal__form'>
          <p className='feedback-modal__form-title'>
            Love Letterbox? Share why or how we can improve it!
          </p>

          <form onSubmit={handleSubmitClicked}>
            <textarea
              placeholder="I'm so happy that..."
              value={message}
              rows={4}
              onChange={e => setMessage(e.target.value)}
            >
            </textarea>

            <a
              class="feedback-modal__alternative-feedback"
              href="mailto:vic@myletterbox.io"
            >
              Or drop me an email at vic@myletterbox.io
            </a>

            <div className='feedback-modal__action-container'>
              <button
                className='feedback-modal__cancel-button'
                onClick={handleClose}
              >
                Cancel
              </button>

              <button
                type="submit"
                className={`${hasSent ? 'submitted' : ''} feedback-modal__submit-button`}
              >
                {hasSent
                  ? 'Thanks!' : (
                    isSendingFeedback ? (<CircleLoader />) : (
                      <span>
                        Send feedback
                        <ArrowIcon />
                      </span>
                    )
                  )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: getUserEmail(state)
});

const mapDispatchToProps = {
  sendFeedback,
  closeFeedbackModal
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
