import { FETCH_USERS, CHANGE_STATUS, CHANGE_PLAN } from '../actionTypes/usersTypes';

const users = (state = [], action = {}) => {
    switch (action.type) {
        case FETCH_USERS:
            return action.payload;
        case CHANGE_STATUS:
            return state.reduce((a, c) => {
                if (c._id === action.payload.id) {
                    c.isBlocked = action.payload.isBlocked;
                }

                a.push(c);
                return a;
            }, []);
        case CHANGE_PLAN:
            return state.reduce((a, c) => {
                if (c._id === action.payload.id) {
                    c.plan.name = action.payload.name;
                }
                a.push(c);
                return a;
            }, []);
        default:
            return state;
    }
};

export default users;

export const getUsers = (state) => state.users;
