import CheckIn from '../../layouts/CheckIn';
import FormSignUpEmail from './FormSignUpEmail';
import FormSignIn from './FormSignIn';
import React from 'react';
import { useEffect, useState } from 'react';
import { sendAmplitudeData,setAmplitudeUserDevice} from '../../../utils/amplitude';
import './Home.scss';

const Home = () => {

    const [amplitude,setAmplitude] = useState(true);
     
    useEffect(() => {
        if (amplitude) {

            if (window.location.search) {
                const deviceId = window.location.search.split('?session=')[1];
                setAmplitudeUserDevice(deviceId);
            }

            //analytics
            sendAmplitudeData('Page Viewed', {
                path: window.location.pathname,
                title: `Myletterbox - Home`,
                url: window.location.href,
            });
            setAmplitude(false);
        }
    });

    return (
        <CheckIn>
            <FormSignUpEmail />
            <FormSignIn />
        </CheckIn>
    );
};

export default Home;
